import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { Typography } from "@mui/material/node";
import { ErrorMessage, Field, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import AppBars from "../../common/AppBar/Index";
import { MapView } from "../../common/MapView/MapView";
import NavBar from "../../common/NavBar/NavBar";
import ToastMessage from "../../common/Toast";
import {
  ComponentWithAuth,
  ComponentWithRouterProp,
} from "../../constants/constant";
import { lang } from "../../constants/lang";
import { routes } from "../../constants/routes";
import {
  addLocation,
  addMarker,
  getAddressFromCoordinates,
  updateLocation,
  updateMarker,
  UploadFile,
} from "../../utils/urls";
import MarkerIcon from "../../resources/MarkerIcon.png";
import { calcBoundsFromCoordinates, fitBoundary } from "../../utils/helper";
import PhotoUpload from "../../common/PhotoUpload";
import CloseIcon from "@mui/icons-material/Close";
import "./Style.css";
import FullScreenImage from "../../common/FullScreenImage";
import * as turf from "@turf/turf";
import { OTHER_ICON } from "../../constants/images";

const markers_type = [
  "Pest",
  "Disease",
  "Water",
  "Damage",
  "Scout",
  "Task",
  "Drone",
  "Others",
];

function AddMaker() {
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [centerPoint, setCenterPoint] = useState(
    state && state.center_point ? state.center_point : [0, 0],
  );
  const [marker_point, setMarkerPoint] = useState(null);
  const formRef = useRef({});
  const navigate = useNavigate();
  const [boundary, setBoundary] = useState(state ? true : false);
  const [fitBound, setFitBound] = useState(true);
  const [imgUpload, setImgUpload] = useState(false);
  const [imageFile, setImageFile] = useState(
    state && state.marker_image ? state.marker_image : false,
  );
  const [fullImg, setFullImg] = useState(null);
  const [file, setFile] = useState();
  var style = {};
  style = {
    width: "100%",
    height: "100%",
    display: "block",
  };
  const handleImageChange = (file) => {
    setImageFile(file);
    setImgUpload(false);
  };
  const imageUpload = (file) => {
    setFile(file);
  };
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        if (state && state.boundary && state.boundary.length > 0) {
          const isWithinPolygon = turf.booleanPointInPolygon(
            turf.point([position.coords.longitude, position.coords.latitude]),
            turf.polygon(state.boundary[0].geometry.coordinates),
          );
          if (isWithinPolygon) {
            setCenterPoint([
              position.coords.longitude,
              position.coords.latitude,
            ]);
          }
        }
      },
      (error) => console.log(error),
      {
        enableHighAccuracy: true,
      },
    );
  }, []);

  useEffect(() => {
    getAddress();
  }, [marker_point]);

  const handleAddress = (address) => {
    console.log(
      "🚀 ~ file: AddLocation.js:43 ~ handleAddress ~ address:",
      address,
    );
  };
  /**
   * Function to get address from coordinates
   */
  const getAddress = async () => {
    // setLoading(true)
    let points = marker_point || centerPoint;
    let response = await getAddressFromCoordinates(...points);
    if (response?.features[0]?.place_name) {
      formRef.current.setFieldValue("address", response.features[0].place_name);
    }
    setLoading(false);
  };
  /**
   * Function to create marker
   * @param {*} formData
   */
  const handelMarkerForm = async (formData) => {
    try {
      setLoading(true);
      let data = new FormData();
      data.append("file", file);
      data.append("type", "image/png");
      formData.location_id = state._id;
      formData.center_point = marker_point || centerPoint;
      if (state.mode === "add")
        if (typeof file !== "undefined") {
          var img_res = await UploadFile(data);
          formData.marker_image = img_res.data;
          if (img_res.success) {
            let response = await addMarker(formData);
            ToastMessage({
              type: response?.success ? "success" : "error",
              message: response?.success
                ? lang.AddMarkerSuccess
                : response.message,
            });
            if (response?.success) {
              navigate(-1);
              setLoading(false);
            }
          }
        } else {
          let response = await addMarker(formData);
          ToastMessage({
            type: response?.success ? "success" : "error",
            message: response?.success
              ? lang.AddMarkerSuccess
              : response.message,
          });
          if (response?.success) {
            navigate(-1);
            setLoading(false);
          }
        }
      if (state.mode === "edit") {
        if (typeof file !== "undefined") {
          var img_res = await UploadFile(data);
          formData.marker_image = img_res.data;
          formData.location_id = state.location_id._id;
          if (img_res.success) {
            let response = await updateMarker(formData, state._id);
            ToastMessage({
              type: response?.success ? "success" : "error",
              message: response?.success
                ? lang.UpdateMarkerSuccess
                : response.message,
            });
            if (response?.success) {
              navigate(-1);
              setLoading(false);
            }
          }
        } else {
          formData.location_id = state.location_id._id;
          formData.marker_image = imageFile ? imageFile : null;
          let response = await updateMarker(formData, state._id);
          ToastMessage({
            type: response?.success ? "success" : "error",
            message: response?.success
              ? lang.UpdateMarkerSuccess
              : response.message,
          });
          if (response?.success) {
            navigate(-1);
            setLoading(false);
          }
        }
      }
    } catch (error) {
      console.log("🚀 ~ file: AddLocation.js:121 ~ addlocation~ error:", error);
    }
  };

  const handleImageCloseDialog = () => {
    setFullImg(null);
  };

  return (
    <Box>
      <AppBars
        title={"Field marker"}
        hasAction={true}
        action={() => {
          if (!loading) formRef.current.submitForm();
        }}
      />
      <FullScreenImage
        image={fullImg}
        handleImageCloseDialog={handleImageCloseDialog}
      />

      <div className="responsive-margin-top-header">
        <MapView
          width={"100vw"}
          height={280}
          current_loc={centerPoint}
          marker_point={centerPoint}
          navigationEnabled={false}
          handleAddress={handleAddress}
          disableZoomScroll={true}
          onChangeCurrentLocation={(latLng) => {
            setMarkerPoint(latLng);
          }}
          setMarkers={(value) => {
            setMarkerPoint([value.lng, value.lat]);
          }}
          loading={false}
          defaultFitBounds={
            state && state.boundary && fitBound
              ? calcBoundsFromCoordinates(state.boundary[0])
              : state &&
                state.location_id &&
                state.location_id.boundary &&
                fitBound
              ? calcBoundsFromCoordinates(state.location_id.boundary[0])
              : {}
          }
          polygonCoordinates={
            state && state.boundary
              ? state.boundary[0].geometry.coordinates
              : [] ||
                (state &&
                  state.location_id &&
                  state.location_id.boundary.length > 0)
              ? state.location_id.boundary[0].geometry.coordinates
              : {}
          }
          markerImage={OTHER_ICON}
          hasFitBound={fitBound}
        />
      </div>

      {loading && (
        <Box
          sx={{
            position: "absolute",
            zIndex: 999,
            left: 0,
            right: 0,
            top: "50%",
            bottom: 0,
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}

      <Box>
        <Formik
          innerRef={formRef}
          initialValues={{
            name: state && state.mode !== "add" ? state.name : "",
            address: state && state.mode !== "add" ? state.address : "",
            type: state && state.mode !== "add" ? state.type : "",
            description: state && state.mode !== "add" ? state.description : "",
          }}
          validationSchema={Yup.object({
            name: Yup.string().required(lang.nameRequiredMessage),
            address: Yup.string().required(lang.addressRequiredMessage),
            type: Yup.string().required(lang.typeRequireMessage),
            description: Yup.string().required(lang.descriptionRequireMessage),
          })}
          onSubmit={handelMarkerForm}
        >
          <Box
            sx={{
              m: 2,
              textAlign: "left",
              display: "flex",
              flexDirection: "column",
              rowGap: 2,
            }}
          >
            <Box
              sx={{
                mb: 1,
              }}
            >
              <Typography
                color="primary"
                style={{
                  fontWeight: "bold",
                  fontSize: 20,
                }}
              >
                Field Marker Information
              </Typography>
            </Box>
            <Field name="name">
              {({ field, form: { touched, errors }, meta }) => (
                <div>
                  <TextField
                    {...field}
                    placeholder="Marker name"
                    fullWidth
                    variant="outlined"
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="error-message"
                  />
                </div>
              )}
            </Field>
            <Field name="address">
              {({ field, form: { touched, errors }, meta }) => (
                <div>
                  <TextField
                    {...field}
                    placeholder="Address"
                    fullWidth
                    variant="outlined"
                  />
                  <ErrorMessage
                    name="address"
                    component="div"
                    className="error-message"
                  />
                </div>
              )}
            </Field>
            <Field name="type">
              {({ field, form: { touched, errors }, meta }) => (
                <div>
                  <FormControl fullWidth>
                    <Select
                      {...field}
                      displayEmpty
                      renderValue={(value) => value || "Type"}
                      MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                      sx={{
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#3DB3C0",
                          color: "rgb(112, 116, 145)",
                        },
                      }}
                    >
                      {markers_type.map((val, index) => (
                        <MenuItem key={index} value={val}>
                          {val}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <ErrorMessage
                    name="type"
                    component="div"
                    className="error-message"
                  />
                </div>
              )}
            </Field>
            <Field name="description">
              {({ field, form: { touched, errors }, meta }) => (
                <div>
                  <TextField
                    {...field}
                    placeholder="Marker Description"
                    variant="outlined"
                    fullWidth
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="error-message"
                  />
                </div>
              )}
            </Field>
          </Box>
        </Formik>
      </Box>

      {!imageFile ? (
        <Box
          sx={{
            m: 2,
          }}
        >
          <Button
            onClick={() => {
              setImgUpload((prev) => !prev);
            }}
          >
            Upload Image
          </Button>
        </Box>
      ) : (
        <div
          style={{
            display: "flex",
            margin: "1rem",
            flexDirection: "column",
            alignItems: "center",
            position: "relative",
          }}
        >
          <img
            src={imageFile}
            alt="Captured"
            style={style}
            onClick={() => setFullImg(imageFile)}
          />
          <CloseIcon
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              color: "red",
              backgroundColor: "white",
            }}
            onClick={() => {
              setImageFile(null);
              setFullImg(false);
            }}
          />
        </div>
      )}

      {imgUpload ? (
        <PhotoUpload
          onImageChange={handleImageChange}
          imageUpload={imageUpload}
        />
      ) : (
        ""
      )}
    </Box>
  );
}

export default ComponentWithAuth(ComponentWithRouterProp(AddMaker));
