import { ThemeProvider } from "@mui/material/styles";
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import appTheme from './constants/themes/themes';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react';
import { persister, store } from "./store";
import { AuthContextProvider } from "./layouts/Chat/context/AuthContext";
import { ChatContextProvider } from "./layouts/Chat/context/ChatContext";
import "react-chat-elements/dist/main.css"
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={appTheme}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persister}>
        <AuthContextProvider>
          <ChatContextProvider>
            <App />
          </ChatContextProvider>
        </AuthContextProvider>
      </PersistGate>
    </Provider>
  </ThemeProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
