import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { ChatItem, Navbar } from "react-chat-elements";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
import { db } from "../../../../ firebase";
import { AuthContext } from "../../context/AuthContext";
import {
  collection,
  query,
  where,
  getDocs,
  setDoc,
  doc,
  updateDoc,
  serverTimestamp,
  getDoc,
  limit,
  orderBy,
  startAfter,
  startAt,
  endAt,
} from "firebase/firestore";
import { debounce } from "lodash";
import LoadingState from "../../../../common/Loader";
import theme from "../../../../constants/themes/themes";
import NoDataFound from "../../../../common/NoDataFound";
import {
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "../../../../layouts/Locations/style";
import SearchIcon from "../../../../resources/SearchIcon.svg";
import { lang } from "../../../../constants/lang";
import { currentUserFireDBDetails } from "../../../../utils/functions";

export default function NewConversation() {
  const [users, setUsers] = useState([]);
  const [username, setUsername] = useState("");
  const [user, setUser] = useState(null);
  const [lastKey, setLastKey] = useState("");
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(20);
  const [backDropLoader, setBackDropLoader] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  let navigate = useNavigate();
  const observer = useRef();
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    fetchUser();
  }, []);

  const handleSelect = async (user) => {
    setBackDropLoader(true);
    let fireUserDetails = currentUserFireDBDetails();
    //check whether the group(chats in firestore) exists, if not create
    const combinedId =
      currentUser.uid > user.uid
        ? currentUser.uid + user.uid
        : user.uid + currentUser.uid;
    try {
      const res = await getDoc(doc(db, "chats", combinedId));
      if (!res.exists()) {
        //create a chat in chats collection
        await setDoc(doc(db, "chats", combinedId), { messages: [] });
        const checkChatListExitCurrentUser = await getDoc(
          doc(db, "userChats", currentUser.uid),
        );
        const checkChatListExitReceiver = await getDoc(
          doc(db, "userChats", user.uid),
        );
        if (!checkChatListExitCurrentUser.exists()) {
          await setDoc(doc(db, "userChats", currentUser.uid), {});
        }
        if (!checkChatListExitReceiver.exists()) {
          await setDoc(doc(db, "userChats", user.uid), {});
        }
        //create user chats

        await updateDoc(doc(db, "userChats", currentUser.uid), {
          [combinedId + ".userInfo"]: {
            uid: user.uid,
            userName: user.userName,
            name: user?.name,
            profilePhoto: user?.profilePhoto || "",
          },
          [combinedId + ".chat_id"]: combinedId,
          [combinedId + ".date"]: serverTimestamp(),
        });

        await updateDoc(doc(db, "userChats", user.uid), {
          [combinedId + ".userInfo"]: {
            uid: currentUser.uid,
            userName: currentUser.displayName,
            name: fireUserDetails?.name,
            profilePhoto: fireUserDetails?.photoURL || "",
          },
          [combinedId + ".chat_id"]: combinedId,
          [combinedId + ".date"]: serverTimestamp(),
        });
        return navigate("/chat/conversation", {
          state: {
            conversation_id: res.id,
            messages: res.data(),
            chatUser: user,
          },
        });
      } else {
        return navigate("/chat/conversation", {
          state: {
            conversation_id: res.id,
            messages: res.data(),
            chatUser: user,
          },
        });
      }
    } catch (err) {
      console.error(err);
      setBackDropLoader(false);
    }

    setUser(null);
    setUsername("");
  };

  const fetchUser = async () => {
    setLoading(true);
    let firebaseUsers = [];
    let LastKey = "";
    const q = query(
      collection(db, "users"),
      orderBy("userName", "asc"),
      limit(perPage),
      where("userName", "!=", currentUser?.displayName?.toLowerCase()),
    );
    getDocs(q)
      .then((res) => {
        res.forEach((doc) => {
          firebaseUsers.push(doc.data());
          LastKey = doc.data().userName;
        });
        setLastKey(LastKey);
        setUsers(firebaseUsers);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const fetchNextUsers = () => {
    setLoading(true);
    let firebaseUsers = [];
    let LastKey = "";
    const q = query(
      collection(db, "users"),
      orderBy("userName", "asc"),
      startAfter(lastKey),
      limit(perPage),
      where("userName", "!=", currentUser?.displayName?.toLowerCase()),
    );
    if (lastKey.length > 0) {
      getDocs(q)
        .then((res) => {
          res.forEach((doc) => {
            firebaseUsers.push(doc.data());
            LastKey = doc.data().userName;
          });

          setLastKey(LastKey);
          setLoading(false);
          setUsers(users.concat(firebaseUsers));
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  };

  const searchUser = (inputValue) => {
    setLoading(true);
    let firebaseUsers = [];
    if (inputValue) {
      let search = inputValue?.toLowerCase();
      const q = query(
        collection(db, "users"),
        orderBy("userName", "asc"),
        startAt(search),
        endAt(search + "\uf8ff"),
        limit(30),
        where("userName", "!=", currentUser?.displayName?.toLowerCase()),
      );
      getDocs(q)
        .then((res) => {
          res.forEach((doc) => {
            firebaseUsers.push(doc.data());
          });
          setUsers(firebaseUsers);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      fetchUser();
    }
  };

  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          if (!searchValue) {
            fetchNextUsers();
          }
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading],
  );

  const handleDebouceSearch = async (inputValue) => {
    searchUser(inputValue);
  };

  const debounceSearch = useCallback(debounce(handleDebouceSearch, 1000), []);

  const handleSearch = (e) => {
    setLastKey("");
    setUsers([]);
    setLoading(true);
    setSearchValue(e.target.value);
    debounceSearch(e.target.value);
  };

  return (
    <div>
      <Navbar
        center={<div className="pt-1">New Chat</div>}
        left={
          <div
            onClick={() => {
              navigate("/chat");
            }}
          >
            <ArrowBackIosIcon
              style={{
                width: 30,
                height: 30,
              }}
            />
          </div>
        }
        type="light"
      />

      <div className="container" style={{ margin: "80px 0px 0px 0px" }}>
        <div style={{ margin: "15px 20px" }}>
          <Search>
            <SearchIconWrapper>
              <img src={SearchIcon} />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search by username"
              onChange={handleSearch}
              value={searchValue}
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
        </div>

        {users && users.length > 0 && (
          <>
            {users.map((user, index) => (
              <div
                ref={users?.length === index + 1 ? lastElementRef : null}
                key={user?.uid}
              >
                <ChatItem
                  avatar={
                    user?.profilePhoto
                      ? user?.profilePhoto
                      : `https://ui-avatars.com/api/?name=${user?.name[0]}`
                  }
                  alt="kursat_avatar"
                  title={
                    <div>
                      <div className="chat-name">{user?.name}</div>

                      <div className="chat-user-name">
                        {`@${user?.userName}`}
                      </div>
                    </div>
                  }
                  date={null}
                  onClick={() => {
                    handleSelect(user);
                  }}
                />
              </div>
            ))}
          </>
        )}
        {loading && (
          <div style={{ textAlign: "center" }}>
            <LoadingState color={theme.palette.primary.main} />
          </div>
        )}

        {users && users?.length === 0 && !loading && (
          <NoDataFound title={lang.noUserFound} />
        )}

        {backDropLoader && (
          <div className="backdrop-loader">
            <LoadingState
              width="50px"
              height="50px"
              color={theme.palette.primary.main}
            />
          </div>
        )}
      </div>
    </div>
  );
}
