import React, { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { getUserList } from "../../utils/urls";
import _ from 'lodash';

function UserSelectComponent({ handleSelectUser, defaultUser = null}) {
  const [userList, setUserList] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const searchUsers = async (searchQuery) => {
    if (searchQuery) {
      try {
        let response = await getUserList(searchQuery);
        if (response.success && response.user.length > 0) {
          return response.user
        } else {
          return []
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  
  // load options using API call
  const loadOptions = _.debounce((inputValue) => {
    searchUsers(inputValue).then(response => {
      if(!_.isEmpty(response)){
      setUserList(response)
    }
    })
  }, 800)

  return (
    <Autocomplete
      multiple
      id="user-select"
      options={userList}
      getOptionLabel={(option) => option.user_name}
      value={defaultUser|| []}
      inputValue={inputValue}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
        loadOptions(newInputValue);
      }}
      onChange={(_, newValue) => {
        handleSelectUser(newValue);
      }}
      sx={{
        fontSize: '14px',
        lineHeight: '24px',
        fontFamily: '"PoppinsRegular","Helvetica","Arial",sans-serif'
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder="Search and add users"
          margin="normal"
          sx={{
            marginTop: 0,
          }}
        />
        )}
    />
  )
}

export default UserSelectComponent
