import {
  Box,
  Avatar,
  Typography,
  Badge,
  TextField,
  Button,
  Dialog,
  DialogActions,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import AppBars from "../../common/AppBar/Index";
import NavBar from "../../common/NavBar/NavBar";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { getCurrentUser, handleEditProfileData } from "../../utils/urls";
import {
  ComponentWithAuth,
  ComponentWithRouterProp,
} from "../../constants/constant";
import { connect } from "react-redux";
import {
  logoutUser,
  setLoginData,
  setUserData,
} from "../../store/action/action";
import { routes } from "../../constants/routes";
import { store } from "../../store";
import PhoneInput from "../../common/PhoneInput/PhoneInput";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { lang } from "../../constants/lang";
import { signUpValidation } from "../../constants/validationMessages";
import { REGEX } from "../../constants/regex";
import {
  ChangeProfileLabel,
  ErrorMessageStyle,
  FieldContainer,
  FormFields,
  Label,
  ProfileContainer,
  TextFieldContainer,
  Toaststyle,
  FormContainer,
} from "./style";
import LoadingState from "../../common/Loader";
import theme from "../../constants/themes/themes";
import { UploadFile, handleChangePassword } from "../../utils/urls";
import ToastMessage from "../../common/Toast";
import { DialogTitle } from "@mui/material/node";
import {
  passwordValidation,
  defaultMessage,
} from "../../constants/validationMessages";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  newPasswordMatch,
  cpasswordRequired,
  passwordChangeMessage,
} from "../../constants/validationMessages";
import "./style.css";
import { updateProfile } from "firebase/auth";
import { auth, db } from "../../ firebase";
import { updateDoc, doc } from "firebase/firestore";
import { matchIsValidTel } from "mui-tel-input";
import InviteDrawerComponent from "../../common/Drawers/InviteDrawer";

function Profile(props) {
  const data = useRef({});
  const formRef = useRef();
  const formRefChangePassword = useRef({});
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [userId, setUserId] = useState("");
  const [selectedImage, setSelectedImage] = useState("");
  const [imageToUpload, setImageToUpload] = useState("");
  const inputRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openDialog, setDialogOpen] = useState(false);
  const [changePasswordDialogOpen, setChangePasswordDialogOpen] =
    useState(false);
  const [showPassword, setShowPassword] = useState({
    newPassword: false,
    confrimNewPassword: false,
  });
  const [changePasswordLoading, setChangePassswordLoading] = useState(false);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
  const [shareDrawerOpen, setShareDrawerOpen] = useState(false);

  const handleClick = () => {
    inputRef.current.click();
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setChangePasswordDialogOpen(false);
    setShowPassword({
      ...showPassword,
      newPassword: false,
      confrimNewPassword: false,
    });
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const response = await getCurrentUser();
      setIsLoading(false);
      props.setUserDataAction(response);
      // console.log(response);
      const state = store.getState();
      data.current = state?.userData.user;
      setUserId(state?.userData?.user?._id);
      data?.current.profile_pic && setSelectedImage(data.current.profile_pic);
    })();
  }, []);

  const handleLogout = () => {
    props.logoutUser({});
    props.setLoginDataAction("");
    props.setUserDataAction([]);
    localStorage.removeItem("userToken");
    localStorage.clear();
    navigate(routes.LOGIN);
  };

  const handleEditProfile = async (formData) => {
    if (!isValidPhoneNumber) {
      return;
    }
    setIsLoading(true);
    try {
      let data = new FormData();
      data.append("file", imageToUpload);
      data.append("type", "image/png");
      let imageRes = "";
      if (imageToUpload) {
        imageRes = await UploadFile(data);
      }
      let response = await handleEditProfileData({
        ...formData,
        userId,
        profile_pic: imageRes.data,
      });
      await updateProfile(auth.currentUser, {
        photoURL: imageRes?.data
          ? imageRes?.data
          : selectedImage
          ? selectedImage
          : "",
      });
      await updateDoc(doc(db, "users", auth.currentUser.uid), {
        profilePhoto: imageRes?.data
          ? imageRes?.data
          : selectedImage
          ? selectedImage
          : "",
      });
      ToastMessage({
        type: response?.error ? "error" : "success",
        message: response.message,
      });
      props.router.navigate("/");
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  };

  const onSubmit = () => {
    formRef["current"].submitForm();
  };

  const onSubmitChangePassword = () => {
    formRefChangePassword["current"].submitForm();
  };

  const handleSubmitChangePassword = async (formData) => {
    setChangePassswordLoading(true);
    let payload = {
      password: formData?.newPassword,
    };
    try {
      let response = await handleChangePassword(payload);
      if (response.success) {
        ToastMessage({
          type: "success",
          message: passwordChangeMessage,
        });
        setChangePasswordDialogOpen(false);
      } else if (response.message) {
        ToastMessage({
          type: "error",
          message: response.message,
        });
      }
      setShowPassword({
        ...showPassword,
        newPassword: false,
        confrimNewPassword: false,
      });
      setChangePassswordLoading(false);
    } catch (err) {
      console.log("err", err.response);
      ToastMessage({
        type: "error",
        message: defaultMessage,
      });
      setChangePassswordLoading(false);
    }
  };

  const handleImageChange = async (e) => {
    let imageUrl = URL.createObjectURL(e.target.files[0]);
    setSelectedImage(imageUrl);
    setImageToUpload(e.target.files[0]);
  };

  const handlePhoneBlur = (e) => {
    let isValidPhone = matchIsValidTel(e.target.value);
    if (!isValidPhone && e.target.value) {
      setIsValidPhoneNumber(false);
    } else {
      setIsValidPhoneNumber(true);
    }
  };

  const openSMS = () => {
    const userAgent = window.navigator.userAgent;
    const body = encodeURIComponent(lang.emailInviteBody);
  
    let smsLink;
  
    if (/iPhone|iPad|iPod/i.test(userAgent)) {
      // iOS device
      smsLink = `sms:&body=${body}`;
    } else {
      // Android device or other platforms
      smsLink = `sms:?body=${body}`;
    }
  
    window.location.href = smsLink;
  };

  return (
    <Box>
      <AppBars
        title={lang.EditProfile}
        hasAction={true}
        action={onSubmit}
        hasActionCancel={false}
      />
      {isLoading ? (
        <Box sx={{ margin: "20%" }}>
          <LoadingState color={theme.palette.primary.main} />
        </Box>
      ) : (
        <Formik
          innerRef={formRef}
          initialValues={{
            user_name: data?.current.user_name,
            full_name: data?.current.full_name,
            email: data?.current.email,
            role: data?.current.role,
            mobile: data?.current.mobile,
          }}
          enableReinitialize="true"
          validationSchema={Yup.object({
            user_name: Yup.string()
              .matches(REGEX.USERNAME, signUpValidation.userName.match)
              .min(6, signUpValidation.userName.min)
              .max(30, signUpValidation.userName.max)
              .required(signUpValidation.userName.required),
            full_name: Yup.string()
              .matches(REGEX.NAME, signUpValidation.full_name.match)
              .min(5, signUpValidation.full_name.min)
              .max(50, signUpValidation.full_name.max)
              .required(signUpValidation.full_name.required),
            email: Yup.string()
              .required(signUpValidation.email.required)
              .email(signUpValidation.email.validEmail),
            role: Yup.string(),
          })}
          onSubmit={handleEditProfile}
        >
          <Form style={{ marginBottom: "60px", marginTop: "85px" }}>
            <ProfileContainer>
              <Badge
                overlap="circular"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                badgeContent={
                  <EditOutlinedIcon
                    style={{
                      height: 15,
                      width: 15,
                      padding: 3,
                      color: "#ffff",
                      background: "#1B2046",
                      borderRadius: 40,
                    }}
                    onClick={handleClick}
                  />
                }
              >
                {selectedImage ? (
                  <Avatar
                    style={{
                      width: 96,
                      height: 96,
                    }}
                    alt="Remy Sharp"
                    src={selectedImage}
                  />
                ) : (
                  <Avatar
                    style={{
                      width: 96,
                      height: 96,
                    }}
                    alt="Remy Sharp"
                    // src={file}
                  >
                    <Typography
                      variant={"h1"}
                      style={{
                        textTransform: "uppercase",
                      }}
                    >
                      {data?.current?.full_name?.[0]}
                      {data?.current?.full_name?.[1]}
                    </Typography>
                  </Avatar>
                )}
              </Badge>

              <ChangeProfileLabel
                style={{ color: "#3DB3C0" }}
                onClick={handleClick}
              >
                <input
                  type="file"
                  style={{ display: "none" }}
                  ref={inputRef}
                  onChange={handleImageChange}
                />
                {lang.ChangeProfilePhoto}
              </ChangeProfileLabel>
            </ProfileContainer>
            <FormFields>
              <FieldContainer>
                <Label>{lang.name} </Label>
                <Field name="full_name">
                  {({ field, form: { touched, errors }, meta }) => (
                    <TextFieldContainer>
                      <TextField
                        {...field}
                        placeholder={lang.enter(lang.fullName)}
                        fullWidth
                        variant="standard"
                      />
                      <ErrorMessage
                        name="full_name"
                        component="span"
                        style={ErrorMessageStyle}
                      />
                    </TextFieldContainer>
                  )}
                </Field>
              </FieldContainer>
              <FieldContainer>
                <Label>{lang.userName}</Label>
                <Field name="user_name">
                  {({ field, form: { touched, errors }, meta }) => (
                    <TextFieldContainer>
                      <TextField
                        {...field}
                        placeholder={lang.enter(lang.userName)}
                        fullWidth
                        disabled
                        variant="standard"
                      />
                      <ErrorMessage
                        name="user_name"
                        component="span"
                        style={ErrorMessageStyle}
                      />
                    </TextFieldContainer>
                  )}
                </Field>
              </FieldContainer>
              <Box>
                <FieldContainer>
                  <Label>{lang.email}</Label>
                  <Field name="email">
                    {({ field, form: { touched, errors }, meta }) => (
                      <TextFieldContainer>
                        <TextField
                          {...field}
                          placeholder={lang.enter(lang.emailAddress)}
                          fullWidth
                          disabled
                          variant="standard"
                        />
                      </TextFieldContainer>
                    )}
                  </Field>
                </FieldContainer>
                <ErrorMessage
                  name="email"
                  component="span"
                  style={ErrorMessageStyle}
                />
              </Box>
              <FieldContainer>
                <Label>{lang.phone}</Label>
                <Field name="mobile">
                  {({ field, form, meta }) => (
                    <TextFieldContainer>
                      <PhoneInput
                        onPhoneChange={(val) =>
                          form.setFieldValue(
                            "mobile",
                            val.replace(REGEX.MOBILE, ""),
                          )
                        }
                        handlePhoneBlur={(e) => handlePhoneBlur(e, form)}
                        setIsValidPhoneNumber={setIsValidPhoneNumber}
                        variant="standard"
                        value={data?.current?.mobile}
                      />
                      {!isValidPhoneNumber && (
                        <div
                          style={{
                            color: "red",
                            fontFamily: "PoppinsRegular",
                            fontSize: "12px",
                          }}
                        >
                          {signUpValidation.phone.validPhone}
                        </div>
                      )}
                    </TextFieldContainer>
                  )}
                </Field>
              </FieldContainer>

              <FieldContainer>
                <Label>{lang.role} </Label>
                <Field name="role">
                  {({ field, form: { touched, errors }, meta }) => (
                    <TextFieldContainer>
                      <TextField
                        {...field}
                        placeholder={lang.enter(lang.role)}
                        fullWidth
                        variant="standard"
                      />
                      <ErrorMessage
                        name="role"
                        component="span"
                        style={ErrorMessageStyle}
                      />
                    </TextFieldContainer>
                  )}
                </Field>
              </FieldContainer>
              <Button
                variant="contained"
                color="secondary"
                fullWidth
                onClick={() => {
                  setShareDrawerOpen(true);
                }}
              >
                Invite Users
              </Button>
              <div style={{ display: "flex", margin: "10px 0px" }}>
                <Button
                  style={{ marginRight: 15 }}
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => {
                    setChangePasswordDialogOpen(true);
                  }}
                >
                  {lang.changePassword}
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  fullWidth
                  onClick={() => {
                    setDialogOpen(true);
                  }}
                >
                  {lang.LogOut}
                </Button>
              </div>
            </FormFields>
          </Form>
        </Formik>
      )}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Typography variant="subhead1">
            {lang.LogoutConfirmMessage}
          </Typography>
        </DialogTitle>

        <DialogActions>
          <Button
            variant="containedLight"
            className="cancel-button"
            onClick={handleCloseDialog}
          >
            {lang.CancelBtnText}
          </Button>
          <Button
            variant="containedLight"
            onClick={handleLogout}
            autoFocus
            className="positive-button"
          >
            {lang.LogoutBtnText}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={changePasswordDialogOpen}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" style={{ padding: "10px 15px" }}>
          <Typography variant="subhead1"></Typography>
        </DialogTitle>
        <div>
          {changePasswordLoading && (
            <div className="backdrop-loader">
              <LoadingState
                width="50px"
                height="50px"
                color={theme.palette.primary.main}
              />
            </div>
          )}

          <Formik
            innerRef={formRefChangePassword}
            initialValues={{
              newPassword: "",
              confirmNewPassword: "",
            }}
            validationSchema={Yup.object({
              newPassword: Yup.string()
                .required(signUpValidation.password.required)
                .matches(REGEX.PASSWORD, passwordValidation),
              confirmNewPassword: Yup.string()
                .required(cpasswordRequired)
                .oneOf([Yup.ref("newPassword"), null], newPasswordMatch),
            })}
            onSubmit={handleSubmitChangePassword}
          >
            <Form>
              <FormContainer>
                <Field name="newPassword">
                  {({ field, form: { touched, errors }, meta }) => (
                    <div>
                      <label className="field-label-change-password">
                        {lang.newPassword}
                      </label>
                      <OutlinedInput
                        {...field}
                        placeholder={lang.newPassword}
                        fullWidth
                        type={showPassword.newPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                setShowPassword({
                                  ...showPassword,
                                  newPassword: !showPassword.newPassword,
                                })
                              }
                              edge="end"
                            >
                              {showPassword.newPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      <ErrorMessage
                        name="newPassword"
                        component="span"
                        className="error-message"
                        style={{ fontSize: 11, lineHeight: 1 }}
                      />
                    </div>
                  )}
                </Field>
                <Field name="confirmNewPassword">
                  {({ field, form: { touched, errors }, meta }) => (
                    <div>
                      <label className="field-label-change-password">
                        {lang.confirmNewPassword}
                      </label>
                      <OutlinedInput
                        {...field}
                        placeholder={lang.confirmNewPassword}
                        fullWidth
                        type={
                          showPassword.confrimNewPassword ? "text" : "password"
                        }
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() =>
                                setShowPassword({
                                  ...showPassword,
                                  confrimNewPassword:
                                    !showPassword.confrimNewPassword,
                                })
                              }
                              edge="end"
                            >
                              {showPassword.confrimNewPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      <ErrorMessage
                        name="confirmNewPassword"
                        component="span"
                        className="error-message"
                        style={{ fontSize: 11, lineHeight: 1 }}
                      />
                    </div>
                  )}
                </Field>
                <DialogActions style={{ padding: "0px" }}>
                  <Button
                    variant="containedLight"
                    className="cancel-button"
                    onClick={handleCloseDialog}
                  >
                    {lang.CancelBtnText}
                  </Button>
                  <Button
                    variant="containedLight"
                    className="positive-button"
                    autoFocus
                    onClick={onSubmitChangePassword}
                  >
                    {lang.submit}
                  </Button>
                </DialogActions>
              </FormContainer>
            </Form>
          </Formik>
        </div>
      </Dialog>
      <InviteDrawerComponent
        open={shareDrawerOpen}
        position={"bottom"}
        onClose={() => setShareDrawerOpen(false)}
        openSMS={openSMS}
      />
      <NavBar />
    </Box>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setUserDataAction: (payload) => dispatch(setUserData(payload)),
  setLoginDataAction: (payload) => dispatch(setLoginData(payload)),
  logoutUser: (payload) => dispatch(logoutUser(payload)),
});
const mapStateToProps = ({ userData }) => ({
  userData: userData,
});
export default ComponentWithAuth(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ComponentWithRouterProp(Profile)),
);
