import { Avatar } from "@mui/material";
import React from "react";
import {
  ComponentWithAuth,
  ComponentWithRouterProp,
} from "../../constants/constant";
import LocationPinIcon from "../../resources/LocationPinIcon.svg";
import messageIcon from "../../assets/Icons/messageIcon.png";
import "./index.css";
import "../Locations/location.css";
import { useNavigate } from "react-router-dom";
import { boldstring } from "../../utils/DateTime";
import { getTimeFromNow, getMarkerIconType } from "../../utils/functions";
import { SATELLITE_ICON } from "../../constants/images";
import { Typography } from "@mui/material/node";
import { lang } from "../../constants/lang";

function NotificationList({ notificationDetails, index }, props) {
  const navigate = useNavigate();
  return (
    <>
      {index === 0 && <div className="separator-border"></div>}{" "}
      <div
        className="notification-list-main"
        onClick={() => {
          if (
            notificationDetails?.type !== "INVITED" &&
            notificationDetails?.marker_id
          ) {
            navigate("markerdetails", {
              state: {
                id: notificationDetails?.marker_id._id,
                fromNotifications: true,
                data: notificationDetails,
              },
            });
          } else {
            navigate("/locations/view/1", {
              state: notificationDetails?.location_id,
            });
          }
        }}
      >
        <div className="profile-section">
          <div className="display-flex-align-center" style={{ minWidth: 0 }}>
            <Avatar
              alt="avatar"
              src={
                notificationDetails?.marker_id?.marker_type === "SATELLITE"
                  ? SATELLITE_ICON
                  : notificationDetails?.sender?.profile_pic
              }
              style={{ marginRight: 10, textTransform: "capitalize" }}
            >
              {notificationDetails?.sender?.full_name?.charAt(0)}
            </Avatar>
            <div style={{ width: "100%", minWidth: 0 }}>
              <div className="profile-name">
                {notificationDetails?.marker_id?.marker_type === "SATELLITE"
                  ? "Satellite Marker"
                  : notificationDetails?.sender?.full_name}
                {notificationDetails?.type === "INVITED" && (
                  <Typography
                    sx={{
                      fontSize: "13px",
                      color: "grey",
                      display: "inline-block",
                      ml: 0.5,
                    }}
                  >
                    {lang.invitationMessage}
                  </Typography>
                )}
              </div>

              <div className="profile-location" style={{ marginBottom: 0 }}>
                <img
                  src={LocationPinIcon}
                  alt="location-pin"
                  style={{ paddingRight: 7 }}
                />
                <div className="notification-address">
                  {notificationDetails?.type === "INVITED" ? (
                    <>{notificationDetails?.location_id?.address}</>
                  ) : (
                    <>
                      {notificationDetails?.marker_id?.marker_type ===
                      "SATELLITE"
                        ? notificationDetails?.marker_id?.location_id?.address
                        : notificationDetails?.marker_id?.address}
                    </>
                  )}
                </div>
                <div style={{ paddingLeft: 3 }}>
                  {getTimeFromNow(notificationDetails?.createdAt)}
                </div>
              </div>
            </div>
          </div>
          <div>
            {notificationDetails?.type === "MANUAL_MARKER" &&
              notificationDetails?.marker_id?.marker_image &&
              getMarkerIconType(
                notificationDetails?.marker_id?.type,
                "40px",
                "40px",
              )}
          </div>
        </div>
        <div style={{ height: "250px", pointerEvents: "none" }}>
          {notificationDetails?.marker_id?.marker_type === "SATELLITE" ? (
            notificationDetails?.marker_id?.satellite_geo?.png_url ? (
              <img
                src={notificationDetails?.marker_id?.satellite_geo?.png_url}
                className="location-map-image"
              />
            ) : (
              ""
            )
          ) : (
            <img
              src={
                notificationDetails?.marker_id
                  ? notificationDetails?.marker_id?.marker_image
                    ? notificationDetails?.marker_id?.marker_image
                    : notificationDetails?.marker_id?.staticImage
                  : notificationDetails?.location_id
                  ? notificationDetails?.location_id?.staticImage
                  : ""
              }
              className="location-map-image"
            />
          )}
        </div>
        <div className="display-flex-align-center" style={{ marginTop: 10 }}>
          <img
            src={messageIcon}
            style={{ textAlign: "left", display: "block", paddingRight: 7 }}
          />
          <div className="comments-count">
            {notificationDetails?.marker_id?.comments?.length}
          </div>
        </div>
        <div className="notification-location-description">
          {notificationDetails?.marker_id?.description}
        </div>
        {notificationDetails?.type === "MENTION" &&
          notificationDetails?.comment_id && (
            <div className="notification-mention-section">
              <Avatar
                alt="avatar"
                src={notificationDetails?.sender?.profile_pic}
                style={{
                  marginRight: 10,
                  textTransform: "capitalize",
                  width: 32,
                  height: 32,
                  fontSize: 14,
                }}
              >
                {notificationDetails?.sender?.full_name?.charAt(0)}
              </Avatar>
              <div>{boldstring(notificationDetails?.comment_id?.content)}</div>
              <div
                className="profile-location"
                style={{ marginBottom: 0, marginLeft: 10 }}
              >
                {getTimeFromNow(notificationDetails?.createdAt)}
              </div>
            </div>
          )}
        <div className="separator-border"></div>
      </div>
    </>
  );
}

export default ComponentWithAuth(ComponentWithRouterProp(NotificationList));
