import { toast } from "react-toastify";
import "./index.css";

const ToastMessage = ({ type, message }) => {
  toast[type === "success" ? "success" : "error"](message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  });
};

export default ToastMessage;
