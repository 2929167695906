
import {
  Box,
  IconButton,
  InputAdornment,
  Link,
  OutlinedInput,
  Typography,
} from "@mui/material";
import React, { useState,useRef,useEffect } from "react";
import { ComponentWithRouterProp } from "../../constants/constant";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { REGEX } from "../../constants/regex";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useNavigate } from "react-router-dom";
import { cpasswordRequired, cpasswordValidation, passwordRequired, passwordValidation, resetPasswordPageRefreshError } from "../../constants/validationMessages";
import Logo from "../../resources/Logo.svg";
import { lang } from "../../constants/lang";
import { routes } from "../../constants/routes";
import { ErrorMessageStyle, Label } from "../Signup/style";
import { handleReset } from "../../utils/urls";
import SubmitButton from "../../common/SubmitButton";
import ToastMessage from "../../common/Toast";
import { setSplashScreen } from "../../store/action/action";
import { connect } from "react-redux";
function ResetPassword(props) {
  const navigate =useNavigate();
  const formRef = useRef({})
  const [isLoading,setIsLoading] = useState(false);
  const [values, setValues] = useState({
    showPassword: false,
    showCPassword:false
  });

  useEffect(() => {

    const navigationType = performance.getEntriesByType('navigation')[0].type;
    // Check if the page was refreshed
    if (navigationType === 'reload') {
      // Redirect to login page with error message
      
      ToastMessage({type:"error",message:resetPasswordPageRefreshError})
      navigate(routes.LOGIN)

    }
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');
    props.setSplashScreen(false)
    if(!token){
      navigate(routes.LOGIN)
    }

  }, [])
  


  const handleResetpassword = async (formData) => {
    try {
      setIsLoading(true);
      const queryParams = new URLSearchParams(window.location.search);
      const token = queryParams.get('token');
      let response = await handleReset({password:formData.password,token});
      setIsLoading(false);
      ToastMessage({type:response?.success? 'success' : 'error',message:response.message})
      if (response?.success) {
        
        navigate(routes.LOGIN)
      }
    } catch (error) {
      console.log("🚀 ~ file: Resetpassword.js:228 ~ handleReset ~ error:", error)
    }
    
  }
  return (
    <Formik
    innerRef={formRef}
    initialValues={{ password: '', cpassword: '' }}
    validationSchema={Yup.object({
      password: Yup.string()
        .required(passwordRequired)
        .matches(REGEX.PASSWORD,passwordValidation),
      cpassword: Yup.string()
        .required(cpasswordRequired)
        .oneOf([Yup.ref('password'), null], cpasswordValidation),
    },
    )}
    onSubmit={handleResetpassword}
  >
    <Form>
    <Box
      sx={{
        textAlign: "left",
      }}
    >
      <Box
        sx={{
          p: 2,
        }}
      >
        <div style={{ margin: "8px 0 16px" }} onClick={() => props.router.navigate("/")}>
          <img src={Logo} alt="pixelar-logo" />
        </div>
        <Typography
          variant="h1"
          color="primary"
          style={{
            fontWeight: "bold",
            fontSize: 28,
          }}
        >
          {lang.resetPassword}
        </Typography>
        <Typography
          color="secondary"
          style={{
            fontWeight: 400,
            fontSize: 14,
          }}
        >
          {lang.enterYourPassword}
        </Typography>
      </Box>
      <Box
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
        }}
      >

        <Box
          sx={{
            mt: 2,
          }}
        >
         <Label>
                {lang.password}
                </Label>
              <Field name="password">
                {({
                  field,
                  form: { touched, errors },
                  meta,
                }) => (
                  <React.Fragment>
                    <OutlinedInput
                    {...field}
                    style={{ width: "100%" }}
                      id="outlined-adornment-password"
                      type={values.showPassword ? 'text' : 'password'}
                      placeholder={lang.enter(lang.password)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={()=>
                              setValues({
                                ...values,
                                showPassword: !values.showPassword,
                              })
                            }
                            edge="end"
                          >
                            {values.showPassword ? <Visibility />:<VisibilityOff /> }
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <ErrorMessage name="password" component='span' style={ErrorMessageStyle}/>
                  </React.Fragment>
                )}
              </Field>
        </Box>

        <Box
          sx={{
            mt: 3,
          }}
        >
         <Label>
                {lang.confirmPassword}
                </Label>
              <Field name="cpassword">
                {({
                  field,
                  form: { touched, errors },
                  meta,
                }) => (
                  <React.Fragment>
                    <OutlinedInput
                    {...field}
                    style={{ width: "100%" }}
                      id="outlined-adornment-cpassword"
                      type={values.showCPassword ? 'text' : 'password'}
                      placeholder={lang.enter(lang.confirmPassword)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={()=>{
                              setValues({
                                ...values,
                                showCPassword: !values.showCPassword,
                              });
                            }}
                            edge="end"
                          >
                            {values.showCPassword ? <Visibility />:<VisibilityOff /> }
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <ErrorMessage name="cpassword" component='div' style={ErrorMessageStyle}/>
                  </React.Fragment>
                )}
              </Field>
        </Box>

        <Box
          sx={{
            mt: 4,
          }}
        >
         
            <SubmitButton isLoading={isLoading} text={lang.resetPassword} onClick={() => {
                formRef['current'].submitForm()
              }} />
        </Box>

        <Box
          sx={{
            textAlign: "center",
            mt: 2,
          }}
        >
          <Typography
            color="secondary"
            variant="bodyLarge"
          >
            <Link
              onClick={() => {
                props.router.navigate(routes.LOGIN);
              }}
              style={{
                textDecoration: "none",
                pointerEvents:isLoading?"none":"",
              }}
            >
              {lang.backToSignIn}
            </Link>
          </Typography>
        </Box>

      </Box>
    </Box>
    </Form>
    </Formik>
  );
}
const mapDispatchToProps = dispatch => ({
  setSplashScreen: (payload) => dispatch(setSplashScreen(payload)),
});
export default connect(null, mapDispatchToProps) (ComponentWithRouterProp(ResetPassword));
