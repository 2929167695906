export const routes = {
  LOGIN: "/login",
  SIGNUP: "/signup",
  FORGOT_PASSWORD: "/forgot_password",
  RESET_PASSWORD: "/reset_password",
  HOME: "/",
  LOCATION: "/locations",
  LOCATION_ADD: "/locations/add",
  NOTFICATION: "/notifications",
  PROFILE: "/profile",
  CHAT: "/chat",
  ADD_MARKER: "/locations/view/1/addmarker",
  EDIT_MARKER: "/locations/view/1/edit",
  CONVERSATION: "/chat/conversation",
  SCOUT: "/scout",
  SCOUT_TASK_ADD: "/scout/add",
};

export const protectedRoutes = [
  routes.LOCATION,
  routes.NOTFICATION,
  routes.PROFILE,
  routes.CHAT,
  routes.LOCATION_ADD,
  routes.HOME,
  routes.ADD_MARKER,
  routes.EDIT_MARKER,
  routes.CONVERSATION,
  routes.SCOUT,
  routes.SCOUT_TASK_ADD,
];
