import {
  AppBar,
  Badge,
  Box,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import React from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { useNavigate } from "react-router-dom";
import "./appbar.css";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { FilterAltOutlined } from "@mui/icons-material";
import theme from "../../constants/themes/themes";
import { stubFalse } from "lodash";
import { MoreIcon } from "../../layouts/Locations/style";
export default function AppBars({
  position = "fixed",
  title,
  isBack = false,
  hasAction = false,
  transparent = false,
  isBackWithLabel = false,
  hasNotifications = false,
  hasActionCancel = true,
  isPop = false,
  action,
  hasFilter = false,
  filterEnable = true,
  filterAction,
  open,
  canEditLocation = false,
  handleLocationDialog,
}) {
  let navigate = useNavigate();
  return (
    <AppBar
      position={position}
      className={transparent ? "background-transparent" : "background"}
    >
      <Toolbar>
        {isBackWithLabel && (
          <Box
            sx={{
              color: "#fff",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBackIosIcon
              style={{
                width: 16,
                height: 20,
              }}
            />
            <Box
              sx={{
                mt: 0.2,
              }}
            >
              <Typography
                style={{
                  fontSize: 13,
                  fontWeight: 500,
                  fontFamily:
                    'PoppinsRegular", "Helvetica", "Arial", sans-serif',
                }}
              >
                Back{" "}
              </Typography>
            </Box>
          </Box>
        )}
        {isBack && (
          <Box
            onClick={() => {
              navigate(-1);
            }}
          >
            <ArrowBackIosIcon
              style={{
                width: 16,
                height: 20,
              }}
            />
          </Box>
        )}
        {hasAction && hasActionCancel && (
          <Typography
            style={{
              fontSize: 13,
              fontWeight: 500,
              fontFamily: 'PoppinsRegular", "Helvetica", "Arial", sans-serif',
              color: "#3DB3C0",
              position: "fixed",
              left: 16,
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel{" "}
          </Typography>
        )}
        {canEditLocation && (
          <MoreIcon>
            <IconButton
              onClick={handleLocationDialog}
              size="large"
              sx={{
                ml: 2,
                borderRadius: "10%",
                color: "#fff",
                height: "1.2rem",
                p: ".6rem",
              }}
              aria-controls={open ? "account-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
            >
              <MoreHorizIcon style={{ fontSize: 30, marginTop: 5 }} />
            </IconButton>
          </MoreIcon>
        )}
        <Box sx={{ flexGrow: 1, alignItems: "center" }}>
          <Typography
            style={{
              fontSize: 16,
              fontWeight: 600,
              fontFamily: 'PoppinsRegular", "Helvetica", "Arial", sans-serif',
            }}
          >
            {title}{" "}
          </Typography>
        </Box>

        {hasAction && (
          <Typography
            style={{
              fontSize: 13,
              fontWeight: 500,
              fontFamily: 'PoppinsRegular", "Helvetica", "Arial", sans-serif',
              color: "#3DB3C0",
              position: "fixed",
              right: 16,
            }}
            onClick={() => {
              !action ? navigate(-1) : action();
            }}
          >
            Done{" "}
          </Typography>
        )}
        {isPop && (
          <Typography
            style={{
              fontSize: 13,
              fontWeight: 500,
              fontFamily: 'PoppinsRegular", "Helvetica", "Arial", sans-serif',
              color: "#fff",
            }}
          >
            <MoreHorizIcon onClick={action} />{" "}
          </Typography>
        )}
        {hasFilter && (
          <>
            <Badge color="secondary" variant="dot" invisible={filterEnable}>
              <FilterAltOutlined
                onClick={() => {
                  filterAction();
                }}
                color={theme.palette.primary.main}
              />
            </Badge>
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}
