
import {  Box, Button} from '@mui/material'
import React, { useState, useEffect, useRef } from 'react'
import AppBars from '../../common/AppBar/Index'
import NavBar from '../../common/NavBar/NavBar'
import {
  ComponentWithRouterProp,
  ComponentWithAuth,
} from '../../constants/constant'
import LocationList from './LocationList'


const Locations =(props) => {
  const [filter, setFilter] = useState(false)  
  const [filterStatus, setFilterStatus] = useState(true)  
  return (
    <>
      <AppBars title={'Supported Locations'} hasNotifications={'true'} hasFilter={true} filterAction={()=>{
        setFilter(filter ? false: true)
      }} filterEnable={filterStatus} />
      <Box>
        <Box
          sx={{
            m: 2,
          }}
        >

          <LocationList filter={filter} isFilter={(val)=>{
            setFilterStatus(val)
          }}  closeFilter={(val)=>{
            setFilter(val)
          }} />
         
          <NavBar />
        </Box>
      </Box>
    </>
  )
}

export default ComponentWithAuth(ComponentWithRouterProp(Locations))
