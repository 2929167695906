import React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import "./index.css";

function FullScreenImage({ image, handleImageCloseDialog }) {
  return (
    <>
      {image && (
        <>
          <img src={image} className="full-screen-image" />
          <div className="full-screen-close-icon">
            <CancelIcon
              style={{ fontSize: 50, color: "white" }}
              onClick={handleImageCloseDialog}
            />
          </div>
        </>
      )}
    </>
  );
}

export default FullScreenImage;
