import * as mapboxgl from "mapbox-gl";
import * as turf from "@turf/turf";
/**
 * Function to convert coordinates as per right hand rule
 * @param {*} coordinates 
 * @returns 
 */
export function convertToRightHandRule(coordinates) {
  const convertedCoordinates = coordinates.map(([lng, lat]) => [lat, lng])
  return reducePrecision(convertedCoordinates)
}

/**
 * Function to reduce the precision of the coordinates
 * @param {*} coordinates 
 * @returns 
 */
function reducePrecision(coordinates) {
  var reducedCoordinates = [];
  for (var i = 0; i < coordinates.length; i++) {
    var coord = coordinates[i];
    var reducedCoord = [Number(coord[0].toFixed(3)), Number(coord[1].toFixed(3))];
    reducedCoordinates.push(reducedCoord);
  }
  return reducedCoordinates;
}

 // feature reference
 export const fitBoundary = layers => {
		var coordinates = layers.geometry.coordinates[0];
		var bounds = layers.geometry.coordinates[0].reduce(function (bounds, coord) {
			return bounds.extend(coord);
		}, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));
		return bounds
 };





export const calcBoundsFromCoordinates = (coordinatesCollection)=> {
  var bbox = turf.bbox(coordinatesCollection);
  return bbox
}