import { RoomOutlined, SatelliteAltOutlined } from "@mui/icons-material";
import RoomIcon from "@mui/icons-material/Room";
import { Box, Button } from "@mui/material";
import { Typography } from "@mui/material/node";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AppBars from "../../common/AppBar/Index";
import { MapView } from "../../common/MapView/MapView";
import { DRONE_ICON, DRONE_ICON_WHITE } from "../../constants/images";
import { themePalette } from "../../constants/themes/colors";
import { calcBoundsFromCoordinates } from "../../utils/helper";
import { deleteLocation, getMarker } from "../../utils/urls";
import "./location.css";
import { useSelector } from "react-redux";
import ManageLocationDialog from "../../common/ManageLocationDialog/Index";
import PopUp from "../../common/PopUp/Index";
import { lang } from "../../constants/lang";
import ToastMessage from "../../common/Toast";
import { routes } from "../../constants/routes";
import { defaultMessage } from "../../constants/validationMessages";

export default function ViewLocation() {
  const [markerCoordinates, setMarkerCoordinates] = useState(null);
  const [apiData, setApiData] = useState(null);
  const [showHideMapItems, setShowHideMapItems] = useState({
    markers: true,
    satellite: true,
    drone: true,
  });
  const { state } = useLocation();
  const { user } = useSelector((state) => state?.userData);
  const navigate = useNavigate();
  const location = { lng: state.center_point[0], lat: state.center_point[1] };
  const boundaryGeometry = state.boundary[0].geometry;
  const coordinates = boundaryGeometry.coordinates;
  const convertedCoordinates = coordinates[0].map(([lng, lat]) => ({
    lng,
    lat,
  }));
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openDialog, setDialogOpen] = useState(false);
  const [locationData, setLocationData] = useState({});
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getMarker(state._id);
        if (response?.success) {
          let markers = [];
          let formattedCoordinates = null;
          markers = response?.markers?.marker;
          formattedCoordinates = markers?.map((coordinates) => {
            return {
              lng: coordinates?.center_point[0],
              lat: coordinates?.center_point[1],
              id: coordinates?._id,
              type: coordinates?.type,
              satellite_geo: coordinates?.satellite_geo?.features,
              marker_type: coordinates?.marker_type,
            };
          });
          setMarkerCoordinates(formattedCoordinates);
          setApiData(response.markers.marker);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching API data:", error);
      }
    };

    fetchData();
  }, []);

  const showHideMapData = (type) => {
    setShowHideMapItems({
      ...showHideMapItems,
      [type]: !showHideMapItems[type],
    });
  };

  const canEditLocation = user?._id === state?.createdBy;

  const handleLocationDialog = () => {
    setLocationData(state);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setDialogOpen(false);
  };

  const handleDelete = async () => {
    try {
      setAnchorEl(null);
      setLoading(true);
      let response = await deleteLocation(locationData._id);
      if (response && response.success) {
        ToastMessage({
          type: response?.error ? "error" : "success",
          message: response.message,
        });
        navigate(routes.LOCATION);
      }
    } catch (error) {
      ToastMessage({
        type: "error",
        message: defaultMessage,
      });
      console.log("err", error);
    }
  };

  return (
    <>
      <AppBars
        position="fixed"
        transparent={true}
        isBackWithLabel={true}
        open={open}
        canEditLocation={canEditLocation}
        handleLocationDialog={handleLocationDialog}
      />
      <div style={{ position: "relative" }}>
        <MapView
          current_loc={location}
          polygonCoordinates={coordinates}
          markers={markerCoordinates}
          height={"100vh"}
          control={false}
          loading={loading}
          onChangeCurrentLocation={() => {}}
          showHideMapItems={showHideMapItems}
          // markerImage={MarkerIcon}
          defaultFitBounds={calcBoundsFromCoordinates(state.boundary[0])}
          mapType={"Marker"}
        />
        {/* Edit ,Delete Dialog modal */}
        {openDialog && (
          <ManageLocationDialog
            openDialog={openDialog}
            handleCloseDialog={handleCloseDialog}
            locationData={state}
            handleClick={handleClick}
          />
        )}

        {/* delete confirmation Dialogmodal */}
        {open && (
          <PopUp
            {...{
              open,
              content: null,
              title: lang.DeleteLocationConfirmMessage,
              handleCancel: handleClose,
              handleConfirm: handleDelete,
              confirmBtnText: lang.DeleteBtnText,
            }}
          />
        )}
        <Box
          sx={{
            position: "fixed",
            zIndex: 999,
            bottom: 0,
            m: 2,
            left: 0,
            right: 0,
            background: "rgb(0 0 0 / 25%)",
            margin: 0,
            padding: "7px 10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <RoomOutlined
              style={{
                color: "#fff",
                height: 20,
              }}
            />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                textAlign: "left",
              }}
            >
              <Typography
                color="#fff"
                style={{
                  fontWeight: 400,
                  fontSize: 12,
                }}
              >
                <a
                  target="_blank"
                  style={{ color: "#fff" }}
                  href={`http://www.google.com/maps/place/${state.center_point[1]},${state.center_point[0]}`}
                >
                  {" "}
                  {state.address}
                </a>
              </Typography>
              <Box>
                <Typography
                  color="#fff"
                  style={{
                    fontWeight: 500,
                    fontSize: 16,
                    lineHeight: 2,
                  }}
                >
                  {state.name}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box></Box>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Button
              style={{
                borderRadius: 8,
                // width: 185,
                height: 48,
                color: "#707491",
                padding: "10px 8px",
                lineHeight: "1.3",
              }}
              className="add-marker-button"
              variant="contained"
              color="inherit"
              // onClick={()=>{navigate('addmarker')}}
              onClick={() => {
                state.mode = "add";
                navigate("addmarker", { state: state });
              }}
            >
              {" "}
              + Add a marker
            </Button>
            <Button
              className="marker-details-buttons"
              variant="contained"
              color="inherit"
              style={{
                background: showHideMapItems.satellite
                  ? themePalette.palette.secondary.dark
                  : "#E0E0E0",
              }}
              onClick={() => showHideMapData("satellite")}
            >
              <SatelliteAltOutlined
                style={{
                  color: showHideMapItems?.satellite
                    ? themePalette.palette.common.white
                    : "unset",
                }}
              />
            </Button>
            <Button
              className="marker-details-buttons"
              variant="contained"
              color="inherit"
              onClick={() => showHideMapData("drone")}
              style={{
                background: showHideMapItems?.drone
                  ? themePalette.palette.secondary.dark
                  : "#E0E0E0",
              }}
            >
              <img
                src={showHideMapItems.drone ? DRONE_ICON_WHITE : DRONE_ICON}
                width="35px"
                alt="drone"
              />
            </Button>
            <Button
              className="marker-details-buttons"
              variant="contained"
              color="inherit"
              style={{
                background: showHideMapItems.markers
                  ? themePalette.palette.secondary.dark
                  : "#E0E0E0",
              }}
              onClick={() => showHideMapData("markers")}
            >
              <RoomIcon
                style={{
                  fontSize: 30,
                  color: showHideMapItems?.markers
                    ? themePalette.palette.common.white
                    : "unset",
                }}
              />
            </Button>
          </Box>
        </Box>
      </div>
    </>
  );
}
