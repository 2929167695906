import { createTheme } from "@mui/material/styles";
import { sosPrimary, themePalette } from "./colors";

export const themeAppBar = createTheme(themePalette, {
   components:{
    MuiAppBar:{
        defaultProps: {
            shadows: ["none"]

          },
          styleOverrides: {
            root: {
                backgroundColor: sosPrimary[4],
                color:sosPrimary[500],
                boxShadow: '0px 0.3333px 0px rgba(0, 0, 0, 0.3)',
            },
          },
    }
   }
});