export const formatDateTime = (dateTime) => {
  const currentDate = new Date();
  const commentDate = new Date(dateTime);
  const timeDifference = Math.abs(currentDate - commentDate);
  const minutesDifference = Math.floor(timeDifference / 60000);

  if (minutesDifference < 60) {
    return `${minutesDifference}min`;
  } else if (minutesDifference < 1440) {
    const hoursDifference = Math.floor(minutesDifference / 60);
    return `${hoursDifference}h`;
  } else if (minutesDifference < 43200) {
    const daysDifference = Math.floor(minutesDifference / 1440);
    return `${daysDifference}d`;
  } else if (minutesDifference < 525600) {
    const monthsDifference = Math.floor(minutesDifference / 43200);
    return `${monthsDifference}m`;
  } else {
    const yearsDifference = Math.floor(minutesDifference / 525600);
    return `${yearsDifference}y`;
  }
};

export const boldstring = (content) => {
  const words = content.split(" ");

  return words.map((word, index) => {
    if (word.startsWith("@")) {
      return (
        <strong key={index} style={{ color: "black" }}>
          {word}{" "}
        </strong>
      );
    }

    return `${word} `;
  });
};
