import React from "react";
import { useRouteError } from "react-router-dom";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { lang } from "../constants/lang";
import "../App.css";

export default function ErrorPage() {
  const error = useRouteError();
  let navigate = useNavigate();
  return (
    <div id="error-page">
      {error.status === 404 ? (
        <>
          <div className="error-code">404</div>
          <div className="error-message-redirect">{lang.pageDoesNotExist}</div>
          <Button
            style={{ width: 200, marginTop: 40 }}
            onClick={() => {
              navigate("/");
            }}
          >
            {lang.backToHome}
          </Button>
        </>
      ) : (
        <>
          <div>
            <h1 className="error-code" style={{ fontSize: 25 }}>
              {lang.somethingWentWrong}
            </h1>
            <p className="error-message-redirect">{lang.pageRefreshTryAgain}</p>
            <Button
              style={{ width: 200, marginTop: 40 }}
              onClick={() => {
                navigate("/");
              }}
            >
              {lang.backToHome}
            </Button>
          </div>
        </>
      )}
    </div>
  );
}
