import React, { useState, useRef } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  IconButton,
  InputAdornment,
  Link,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import PhoneInput from "../../common/PhoneInput/PhoneInput";
import { ComponentWithRouterProp } from "../../constants/constant";
import Logo from "../../resources/Logo.svg";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { handleSignup } from "../../utils/urls";
import { routes } from "../../constants/routes";
import { lang } from "../../constants/lang";
import {
  passwordValidation,
  signUpValidation,
} from "../../constants/validationMessages";
import {
  Label,
  ErrorMessageStyle,
  FormContainer,
  LogoContainer,
  FormHeading,
} from "./style";
import { REGEX } from "../../constants/regex";
import SubmitButton from "../../common/SubmitButton";
import ToastMessage from "../../common/Toast";
import { setLoginData } from "../../store/action/action";
import { connect } from "react-redux";
import { createUserWithEmailAndPassword, updateProfile } from "firebase/auth";
import { auth, db } from "../../ firebase";
import { doc, setDoc, getDoc } from "firebase/firestore";
import { matchIsValidTel } from "mui-tel-input";
import PopUp from "../../common/PopUp/Index";

function SignUpStepOne(props) {
  const navigate = useNavigate();
  const formRef = useRef({});
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isValidPhoneNumber, setIsValidPhoneNumber] = useState(true);
  const [err, setErr] = useState(false);
  const [open, setOpen] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  /**
   * Function to trigger form submit
   */
  const onSubmit = () => {
    formRef["current"].submitForm();
  };
  const createFirebaseUser = (formData, token) => {
    const handleSubmit = async () => {
      const fullName = formData.full_name;
      const email = formData.email;
      const password = formData.password;
      const userName = formData.user_name.toLowerCase();
      const createdAt = Date.now();
      try {
        //Create user
        const res = await createUserWithEmailAndPassword(auth, email, password);

        //Update profile
        await updateProfile(res.user, {
          displayName: userName,
        });
        //create user on firestore
        await setDoc(doc(db, "users", res.user.uid), {
          uid: res.user.uid,
          userName: userName.toLowerCase(),
          email,
          createdAt,
          name: fullName,
        });

        //create empty user chats on firestore
        await setDoc(doc(db, "userChats", res.user.uid), {});
        getFirebaseUserDBDetails(res.user.uid, token);
        ToastMessage({
          type: "success",
          message: lang.userRegisteredSuccess,
        });
      } catch (err) {
        setIsLoading(false);
        setErr(true);
      }
    };

    handleSubmit();
  };

  const getFirebaseUserDBDetails = (uid, token) => {
    getDoc(doc(db, "users", uid))
      .then((res) => {
        localStorage.setItem("fireUserDetails", JSON.stringify(res.data()));
        localStorage.setItem("userToken", token);
        props.setLoginDataAction(token);
        setOpen(true);
      })
      .catch((err) => {
        setIsLoading(false);
        console.log("err", err);
      });
  };

  const handleSignupForm = async (formData) => {
    if (!isValidPhoneNumber) {
      return;
    }
    try {
      setIsLoading(true);
      let response = await handleSignup(formData);
      if (response?.success) {
        createFirebaseUser(formData, response?.token);
      }

      if (response.error) {
        setIsLoading(false);
        ToastMessage({
          type: "error",
          message: response.message,
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.log(
        "🚀 ~ file: SignUpStepOne.js:46 ~ handleSignup ~ error:",
        error,
      );
    }
  };

  const handlePhoneBlur = (e) => {
    let isValidPhone = matchIsValidTel(e.target.value);
    if (!isValidPhone && e.target.value) {
      setIsValidPhoneNumber(false);
    } else {
      setIsValidPhoneNumber(true);
    }
  };

  const handleConfirm = () => {
    setOpen(false);
    navigate(routes.HOME);
    window.open(process.env.REACT_APP_STRIPE_URL);
  };

  return (
    <>
    <Formik
      innerRef={formRef}
      initialValues={{
        user_name: "",
        full_name: "",
        email: "",
        password: "",
        role: "",
        mobile: "",
      }}
      validationSchema={Yup.object({
        user_name: Yup.string()
          .matches(REGEX.USERNAME, signUpValidation.userName.match)
          .min(6, signUpValidation.userName.min)
          .max(30, signUpValidation.userName.max)
          .required(signUpValidation.userName.required),
        full_name: Yup.string()
          .matches(REGEX.NAME, signUpValidation.full_name.match)
          .min(5, signUpValidation.full_name.min)
          .max(50, signUpValidation.full_name.max)
          .required(signUpValidation.full_name.required),
        email: Yup.string()
          .required(signUpValidation.email.required)
          .email(signUpValidation.email.validEmail),

        role: Yup.string(),
        password: Yup.string()
          .required(signUpValidation.password.required)
          .matches(REGEX.PASSWORD, passwordValidation),
      })}
      onSubmit={handleSignupForm}
    >
      <Form style={{ textAlign: "left" }}>
        <LogoContainer onClick={() => props.router.navigate(routes.HOME)}>
          <img src={Logo} alt="pixelar-logo" />
        </LogoContainer>

        <FormHeading variant="h1" color="primary">
          {lang.createAccount}
        </FormHeading>

        <FormContainer>
          <Box>
            <Label>
              {lang.userName}
              <span style={{ color: "red" }}>*</span>
            </Label>

            <Field name="user_name">
              {({ field, form: { touched, errors }, meta }) => (
                <React.Fragment>
                  <TextField
                    {...field}
                    placeholder={lang.enter(lang.userName)}
                    fullWidth
                  />
                  <ErrorMessage
                    name="user_name"
                    component="span"
                    style={ErrorMessageStyle}
                  />
                </React.Fragment>
              )}
            </Field>
          </Box>
          <Box>
            <Label>
              {lang.fullName}
              <span style={{ color: "red" }}>*</span>
            </Label>
            <Field name="full_name">
              {({ field, form: { touched, errors }, meta }) => (
                <React.Fragment>
                  <TextField
                    {...field}
                    placeholder={lang.enter(lang.fullName)}
                    fullWidth
                  />
                  <ErrorMessage
                    name="full_name"
                    component="span"
                    style={ErrorMessageStyle}
                  />
                </React.Fragment>
              )}
            </Field>
          </Box>
          <Box>
            <Label>
              {lang.emailAddress}
              <span style={{ color: "red" }}>*</span>
            </Label>
            <Field name="email">
              {({ field, form: { touched, errors }, meta }) => (
                <React.Fragment>
                  <TextField
                    {...field}
                    placeholder={lang.enter(lang.emailAddress)}
                    fullWidth
                  />
                  <ErrorMessage
                    name="email"
                    component="span"
                    style={ErrorMessageStyle}
                  />
                </React.Fragment>
              )}
            </Field>
          </Box>
          <Box>
            <Label>{lang.phoneNumber}</Label>
            <Field name="mobile">
              {({ field, form, meta }) => (
                <React.Fragment>
                  <PhoneInput
                    {...field}
                    handlePhoneBlur={(e) => handlePhoneBlur(e, form)}
                    setIsValidPhoneNumber={setIsValidPhoneNumber}
                    onPhoneChange={(val) =>
                      form.setFieldValue(
                        "mobile",
                        val.replace(/^\s+|\s+$/gm, ""),
                      )
                    }
                  />

                  {!isValidPhoneNumber && (
                    <div
                      style={{
                        color: "red",
                        fontFamily: "PoppinsRegular",
                        fontSize: "14px",
                      }}
                    >
                      {signUpValidation.phone.validPhone}
                    </div>
                  )}
                </React.Fragment>
              )}
            </Field>
          </Box>
          <Box>
            <Label>{lang.role}</Label>
            <Field name="role">
              {({ field, form: { touched, errors }, meta }) => (
                <React.Fragment>
                  <TextField
                    {...field}
                    placeholder={lang.enter(lang.role)}
                    fullWidth
                  />
                  <ErrorMessage
                    name="role"
                    component="span"
                    style={ErrorMessageStyle}
                  />
                </React.Fragment>
              )}
            </Field>
          </Box>

          <Box>
            <Label>
              {lang.password} <span style={{ color: "red" }}>*</span>
            </Label>
            <Field name="password">
              {({ field, form: { touched, errors }, meta }) => (
                <React.Fragment>
                  <OutlinedInput
                    {...field}
                    style={{ width: "100%" }}
                    id="outlined-adornment-password"
                    placeholder={lang.enter(lang.password)}
                    type={showPassword ? "text" : "password"}
                    required
                    autoFocus={false}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  <ErrorMessage
                    name="password"
                    component="span"
                    style={ErrorMessageStyle}
                  />
                </React.Fragment>
              )}
            </Field>
          </Box>
          <Box
            sx={{
              mt: 4,
            }}
          >
            <SubmitButton
              isLoading={isLoading}
              text={lang.signup}
              onClick={onSubmit}
            />
          </Box>
          <Box
            sx={{
              textAlign: "center",
            }}
          >
            <Typography variant="bodyLarge" color="secondary">
              {lang.alreadyHaveAccount}{" "}
              <Link
                onClick={() => {
                  props.router.navigate(routes.LOGIN);
                }}
                style={{
                  textDecoration: "none",
                }}
              >
                {lang.signIn}
              </Link>
            </Typography>
          </Box>
        </FormContainer>
      </Form>
    </Formik>
    <PopUp
        {...{
          open,
          content: lang.paymentMessage,
          title: lang.registationSuccess,
          handleConfirm,
          confirmBtnText: "OK"
        }}
      />
    </>
  );
}
const mapDispatchToProps = (dispatch) => ({
  setLoginDataAction: (payload) => dispatch(setLoginData(payload)),
});
const mapStateToProps = ({ userToken }) => ({
  userToken: userToken,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComponentWithRouterProp(SignUpStepOne));
