import { Box, Button, Link, Typography } from '@mui/material';
import React from 'react';
import { ComponentWithRouterProp } from '../../constants/constant';
import Logo from '../../resources/logo-welcome.svg'
import { lang } from '../../constants/lang';
 function Welcome(props) {
    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            height: '100vh',
            flexDirection: 'column',
        }}>
            <Box sx={{
                height: '100%',
                position:'relative'
            }}
            
            style={{backgroundImage: `url(${require('../../resources/BgWelcome.jpg')})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'}}
            >
                <img src={Logo} alt="Pixelar logo" style={{position:'absolute',top:'43%',left:'36%'}}/>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                mt:'25px',
            }}>
                <Typography color="primary" style={{
                    fontWeight: 600,
                    fontSize: 32
                }}>
                   {lang.welcomeToPixelar}
                </Typography>
                <Box sx={{
                    pl: 5,
                    pr: 5,
                    pt: 2
                }}>
                    <Typography color="secondary" style={{
                        fontWeight: 400,
                        fontSize: 14,
                        fontStyle: 'normal'
                    }} >
                       {lang.chooseOptionToLoginOrSignup}, <Link href={process.env.REACT_APP_STRIPE_URL} underline='hover' target="_blank" >{lang.subscribeHere}</Link>
                    </Typography>
                </Box>
            </Box>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                p: 2,
                pt: 0
            }}>
                <Box sx={{
                    margin: 2,
                    marginLeft: 0,
                    marginRight: 0
                }}>
                    <Button onClick={()=>{
                      props.router.navigate('signup')    
                    }} variant="contained">{lang.signup}</Button>
                </Box>
                <Box sx={{
                    marginLeft: 0,
                    marginRight: 0,
                    marginBottom: 2
                }}>
                    <Button onClick={() => {
                        props.router.navigate('login')
                    }} variant="outlined">{lang.signIn}</Button>
                </Box>
            </Box>
        </Box>
    )
}


export default ComponentWithRouterProp(Welcome);