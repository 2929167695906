import React from "react";
import { createBrowserRouter } from "react-router-dom";
import SplashScreen from "../layouts/SplashScreen/Index";
import Login from "../layouts/Login";
import SignUpStepOne from "../layouts/Signup/SignUpStepOne";
import SignUpStepTwo from "../layouts/Signup/SignUpStepTwo";
import ErrorPage from "../utils/ErrorPage";
import Locations from "../layouts/Locations/Locations";
import Notification from "../layouts/Notifications/Notification";
import Profile from "../layouts/Profile/Profile";
import AddLocation from "../layouts/Locations/AddLocation";
import ViewLocation from "../layouts/Locations/ViewLocation";
import AddMarker from "../layouts/Markers/AddMarker";
import ForgotPassword from "../layouts/ForgotPassword";
import ResetPassword from "../layouts/ResetPassword";
import Chat from "../layouts/Chat";
import MarkerDetails from "../layouts/Markers/MarkerDetails";
import Conversation from "../layouts/Chat/layout/Conversation/Conversation";
import NewConversation from "../layouts/Chat/layout/Conversation/NewConversation";
import AddScoutLocation from "../layouts/Scout/AddScoutLocation";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <SplashScreen />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/login",
    element: <Login />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/forgot_password",
    element: <ForgotPassword />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/reset_password",
    element: <ResetPassword />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/locations",
    children: [
      {
        index: true,
        element: <Locations />,
        errorElement: <ErrorPage />,
      },
      {
        path: "add",
        element: <AddLocation />,
        errorElement: <ErrorPage />,
      },
      {
        path: "view/:id",
        children: [
          {
            index: true,
            element: <ViewLocation />,
            errorElement: <ErrorPage />,
          },
          {
            path: "addmarker",
            element: <AddMarker />,
            errorElement: <ErrorPage />,
          },
          {
            path: "edit",
            element: <AddMarker />,
            errorElement: <ErrorPage />,
          },
          {
            path: "markerdetails",
            element: <MarkerDetails />,
            errorElement: <ErrorPage />,
          },
        ],
      },
    ],
  },
  {
    path: "/scout",
    children: [
      {
        index: true,
        element: <AddScoutLocation />,
        errorElement: <ErrorPage />,
      },
      {
        path: "add",
        element: <AddScoutLocation />,
        errorElement: <ErrorPage />,
      },
    ],
  },
  {
    path: "/signup",
    children: [
      {
        index: true,
        element: <SignUpStepOne />,
      },
      {
        path: "step-2",
        element: <SignUpStepTwo />,
        errorElement: <ErrorPage />,
      },
    ],
  },
  {
    path: "/notifications",
    children: [
      {
        index: true,
        element: <Notification />,
        errorElement: <ErrorPage />,
      },
      {
        path: "markerdetails",
        element: <MarkerDetails />,
        errorElement: <ErrorPage />,
      },
    ],
  },
  {
    path: "/profile",
    element: <Profile />,
    errorElement: <ErrorPage />,
  },
  {
    path: "/chat",
    children: [
      {
        index: true,
        element: <Chat />,
        errorElement: <ErrorPage />,
      },
      {
        path: "conversation",
        element: <Conversation />,
        errorElement: <ErrorPage />,
      },
      {
        path: "new",
        element: <NewConversation />,
        errorElement: <ErrorPage />,
      },
    ],
  },
]);
