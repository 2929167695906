import React from "react";
import Drawer from "@mui/material/Drawer";
import MailIcon from "@mui/icons-material/Mail";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import Divider from "@mui/material/Divider";
import { lang } from "../../../constants/lang";
import "../index.css";

function InviteDrawerComponent({ open, position, onClose, header, openSMS }) {
  return (
    <div>
      <Drawer anchor={position} open={open} onClose={onClose}>
        <div className="share-drawer-main">
          <div className="invite-drawer-header">Invite Via</div>
          <Divider />
          <div className="invite-button-container">
            <div className="invite-buttons">
              <a href={`mailto:?subject=${lang.emailInviteSubject}&body=${lang.emailInviteBody}`}>
                <MailIcon className="invite-icon" />
                <div style={{ fontSize: 12 }}>Email</div>
              </a>
            </div>
            <div className="invite-buttons" onClick={() => openSMS()}>
                <PhoneAndroidIcon className="invite-icon" />
                <div style={{ fontSize: 12 }}>SMS</div>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default InviteDrawerComponent;
