import AppleIcon from '@mui/icons-material/Apple';
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import { Box, Button, Divider, FormControl, Link, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import FlagSelectDropDown from '../../common/FlagSelectDropDown';
import SocialButton from '../../common/SocialButton';
import { ComponentWithRouterProp } from '../../constants/constant';
function SignUpStepOne(props) {
    const [state, setState] = useState([]);


    return (
        <Box sx={{
            textAlign: 'left'
        }}>
            <Box sx={{
                p: 2,
                mt: 8
            }}>
                <Typography variant='h1' color="primary" style={{
                    fontWeight: 'bold',
                    fontSize: 28
                }} >
                    Create an Account
                </Typography>
            </Box>
            <Box sx={{
                p: 2,
                pt: 0,
                display: 'flex',
                flexDirection: 'column',
            }}>
                <Typography variant='h3' color="primary" style={{
                    fontWeight: 500,
                    fontSize: 16,
                    fontStyle: 'normal'
                }} >
                    Country
                </Typography>
                <FlagSelectDropDown onchange={(value) => {
                    setState(value)
                }} />
                <Box sx={{
                    mt: 2
                }}>
                    <Typography variant='h3' color="primary" style={{
                        fontWeight: 500,
                        fontSize: 16,
                        fontStyle: 'normal'
                    }} >
                        State
                    </Typography>
                    <FormControl fullWidth>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                        // value={age}
                        // onChange={handleChange}
                        >
                            {state.map((val, index) => <MenuItem key={index} value={val.code}>{val.name}</MenuItem>)}
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{
                    mt: 2
                }}>
                    <Typography variant='h3' color="primary" style={{
                        fontWeight: 500,
                        fontSize: 16,
                        fontStyle: 'normal'
                    }} >
                        Role
                    </Typography>
                    <TextField placeholder='Enter Role' variant='outlined' fullWidth />
                </Box>
                <Box sx={{
                    mt: 4
                }}>
                    <Button variant="contained" onClick={() => {
                        props.router.navigate('/signup/step-2')
                    }} fullWidth>Create Account</Button>
                </Box>
                <Box sx={{
                    mt: 4
                }}>
                    <Divider spacing={4}>
                        <Typography color="secondary" style={{
                            fontWeight: 400,
                            fontSize: 14,
                            fontStyle: 'normal'
                        }} >
                            or sign up with
                        </Typography></Divider>
                </Box>
                <Box sx={{
                    m: 3,
                    display: 'flex',
                    justifyContent: "space-between"
                }}>
                    <SocialButton>
                        <GoogleIcon style={{
                            color: "#707491"
                        }} />
                    </SocialButton>
                    <SocialButton>
                        <FacebookIcon style={{
                            color: "#707491"
                        }} />
                    </SocialButton>
                    <SocialButton>
                        <AppleIcon style={{
                            color: "#707491"
                        }} />
                    </SocialButton>
                </Box>
                <Box sx={{
                    textAlign: 'center'
                }}>
                    <Typography color="secondary" style={{
                        fontWeight: 400,
                        fontSize: 16
                    }} >
                        Already have a Pixelar account <Link onClick={() => {
                            props.router.navigate('/login')
                        }} style={{
                            textDecoration: 'none'
                        }}>Sign In</Link>
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}


export default ComponentWithRouterProp(SignUpStepOne);