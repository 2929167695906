import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const ProfileContainer = styled(Box)(({ theme }) => ({
  "&.MuiBox-root": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "2rem",
    rowGap: "2rem",
  },
}));

export const FormFields = styled(Box)(({ theme }) => ({
  "&.MuiBox-root": {
    display: "flex",
    flexDirection: "column",
    margin: "1rem",
    rowGap: "24px",
  },
}));

export const Label = styled(Typography)(({ theme }) => ({
  "&.MuiTypography-root": {
    fontWeight: 400,
    fontSize: 13,
    fontStyle: "normal",
    lineHeight: "24px",
    color: "#707491",
    width: "40px",
    textAlign: "left",
  },
}));

export const ChangeProfileLabel = styled(Typography)(({ theme }) => ({
  "&.MuiTypography-root": {
    fontSize: "15px",
    fontWeight: 400,
  },
}));

export const TextFieldContainer = styled(Box)(({ theme }) => ({
  "&.MuiBox-root": {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    width: "70vw",
  },
}));

export const FieldContainer = styled(Box)(({ theme }) => ({
  "&.MuiBox-root": {
    display: "flex",
    rowGap: 1,
    columnGap: "56px",
    alignItems: "center",
  },
}));
export const ErrorMessageStyle = {
  color: "red",
  fontFamily: "PoppinsRegular",
  fontSize: "12px",
};

export const Toaststyle = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

export const FormContainer = styled(Box)(({ theme }) => ({
  "&.MuiBox-root": {
    padding: "1rem",
    paddingTop: "0",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
}));
