import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Typography } from "@mui/material/node";
import { lang } from "../../constants/lang";

export default function PopUp({
  open,
  handleClose,
  content,
  title,
  handleCancel,
  handleConfirm,
  cancelBtnText = "Cancel",
  confirmBtnText = "Confirm",
}) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {title && <DialogTitle id="alert-dialog-title"> <Typography variant="subhead1"> {title} </Typography></DialogTitle>}
      {content && 
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        <Typography variant="subhead1"> {content}</Typography>
        </DialogContentText>
      </DialogContent>
      }
      <DialogActions>
        {handleCancel && <Button
          variant="containedLight"
          className="cancel-button"
          onClick={handleCancel}
        >
          {cancelBtnText}
        </Button>}
        {handleConfirm && <Button
          className={confirmBtnText == lang.DeleteBtnText ? "negative-button" : "positive-button"}
          variant="containedLight"
          onClick={handleConfirm}
          autoFocus
        >
          {confirmBtnText}
        </Button>}
      </DialogActions>
    </Dialog>
  );
}
