import React, { useState } from 'react';
import ReactFlagsSelect from "react-flags-select";
import { COUNTRTWITHSTATE } from '../../constants/constant';
import './style.css';
export default function FlagSelectDropDown({onchange}) {
    const [selected, setSelected] = useState("US");
    return (
        <ReactFlagsSelect
            selectButtonClassName="menu-flags-button"
            selectedSize={16}
            optionsSize
            selected={selected}
            onSelect={(code) => {
                console.log(code)
                const state = COUNTRTWITHSTATE.filter(data => data.code2 === code)
                console.log(state)
                onchange(state[0].states)
                setSelected(code)
            }}
        />
    )
}
