import { FormControl } from "@mui/material";
import { withStyles } from "@mui/styles";
import { sosPrimary } from "./colors";

export const themeDropDown = withStyles({
    select: {
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: 'red',
        },
    },
}, (FormControl));