import { Box,Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

export const FormContainer = styled(Box)(({ theme }) => ({
    "&.MuiBox-root": {
        padding: "1rem",
        paddingTop: "0",
        display: "flex",
        flexDirection: "column",
        gap:"16px",
    },
  }))

  export const LogoContainer = styled(Box)(({ theme }) => ({
    "&.MuiBox-root": {
        margin: "31px 0 0 16px",
    },
  }))

  export const FormHeadingContainer = styled(Box)(({ theme }) => ({
    "&.MuiBox-root": {
        marginBottom:"40px",
    },
  }))

  export const FormHeading = styled(Typography)(({ theme }) => ({
    "&.MuiTypography-root": {
        padding:"0 1rem",
        margin:"1rem 0"
    },
  }))
  
  export const Label =styled(Typography)(({theme})=>({
    "&.MuiTypography-root": {
        fontWeight: 500,
        fontSize: 16,
        fontStyle: "normal",
    },
    
  }))

  Label.defaultProps = {
    variant: "h3",
    color:"primary"
  };

  export const ErrorMessageStyle ={
    color: 'red',
    fontFamily: 'PoppinsRegular',
    fontSize: '14px',
  }