import moment from "moment";
import {
  DISEASE_ICON,
  DRONE,
  OTHER_ICON,
  PEST_ICON,
  SCOUT_ICON,
  TASK_ICON,
  WATER_ICON
} from "../constants/images";

let typeArray = [
  { type: "Disease", icon: DISEASE_ICON },
  { type: "Water", icon: WATER_ICON },
  { type: "Pest", icon: PEST_ICON },
  { type: "Others", icon: OTHER_ICON },
  { type: "Task", icon: TASK_ICON },
  { type: "Damage", icon: TASK_ICON },
  { type: "Drone", icon: DRONE },
  { type: "Scout", icon: SCOUT_ICON },
];

export const getMarkerIconType = (type, width, height) => {
  const image = typeArray.filter((icon) => type === icon.type);
  return (
    image[0]?.icon && (
      <img
        src={image[0]?.icon}
        width={width || "30x"}
        height={height || "30px"}
        alt="type"
      />
    )
  );
};

export const getTimeFromNow = (time) => {
  moment.updateLocale("en", {
    relativeTime: {
      future: "in %s",
      past: "%s",
      s: "1s",
      ss: "%ds",
      m: "1min",
      mm: "%dm",
      h: "1h",
      hh: "%dhr",
      d: "1d",
      dd: "%dd",
      M: "1m",
      MM: "%d months",
      y: "1y",
      yy: "%d years",
    },
  });
  let timeStamp = moment(time).unix();
  let unixTime = moment.unix(timeStamp);
  let fromNow = moment(unixTime).fromNow();
  return fromNow;
};

export const currentUserFireDBDetails = (time) => {
  let user = localStorage.getItem("fireUserDetails")
    ? JSON.parse(localStorage.getItem("fireUserDetails"))
    : "";
  return user;
};
