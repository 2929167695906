export const passwordValidation =
  "Password must be 8-15 characters long and contain atleast 1 upper case, 1 lower case, 1 number and 1 special character(@,#,$,&,^,%*)";
export const passwordValidationSmall =
  "Password must contain 8-15 characters, at least 1 upper case, 1 lower case, 1 number and 1 special character";
export const emailValidation = "Please enter a valid email address";
export const loginFormValiadtion =
  "Either email id or the password you have entered is incorrect";
export const forgotPasswordValiadtion = "Email you have entered does not exist";
export const resetPasswordValidation = `"Reset password request expired. Please check latest request send on your email"`;
export const internalServerError = "Internal Server Error";
export const passwordChangeMessage = "Password updated successfully!";
export const resetPasswordPageRefreshError =
  "The action you are trying to do can not be completed due to an error. Please try again later";
export const invalidValidationMessage = (field) => {
  return `Please enter a valid ${field}`;
};
export const emailRequired = "Please enter your email address";
export const passwordRequired = "Please enter your password";
export const cpasswordRequired = "Please confirm your password";
export const cpasswordValidation = "Password and Confirm Password should match";
export const defaultMessage = "Something went wrong!";
export const signUpValidation = {
  userName: {
    match:
      "Username should start with alphabets (A - Z). All others characters can be alphabets, numbers or an underscore ( _ ) or dot ( . ) space is not allowed",
    min: "Username must be minimun 6 characters",
    max: "Username must be maximun 30 characters",
    required: "Please enter your username",
  },
  full_name: {
    match: "Please enter a valid full name",
    min: "Full name is too short",
    max: "Full name is too long",
    required: "Please enter your full name",
  },
  email: {
    validEmail: "Please enter a valid email address",
    required: "Please enter your email address",
  },
  phone: {
    validPhone: "Please enter a valid phone number",
  },
  password: {
    required: "Please enter your password",
  },
};

export const newPasswordMatch =
  "New Password and Confirm Password do not match";
