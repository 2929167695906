import React from "react";
import "./index.css";
import FieldImage from "../../resources/field.png";

function NoDataFound({ title, subTitle, icon, marginTop }) {
  return (
    <div
      className="no-data-found"
      style={{ marginTop: marginTop ? marginTop : 90 }}
    >
      <div>
        <img src={icon ? icon : FieldImage} alt="no-data-found" width={100} />
      </div>
      <div style={{ marginTop: 15 }}>{title}</div>
    </div>
  );
}

export default NoDataFound;
