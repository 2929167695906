import { createTheme } from "@mui/material/styles";
import { sosPrimary } from "./colors";

export const themeInputs = createTheme({
  components: {
    MuiInputBase: {
      styleOverrides: {},
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          zIndex: 0,
        },
      },
    },

    MuiOutlinedInput: {
      defaultProps: {
        color: "primary",
        autoComplete: "off",
      },
      styleOverrides: {
        input: {
          fontSize: "14px",
          lineHeight: "24px",
        },
        root: {
          borderRadius: "12px",
        },
      },
    },

    MuiFilledInput: {
      defaultProps: {
        color: "primary",
      },
      variants: [
        {
          props: { color: "primary", error: false, disabled: false },
          style: {
            backgroundColor: sosPrimary[50],
            "&:hover": {
              backgroundColor: sosPrimary[3],
            },
            "&:focus-within": {
              backgroundColor: sosPrimary[3],
            },
          },
        },
      ],
    },

    MuiTextField: {
      defaultProps: {
        variant: "outlined",
      },
      variants: [
        {
          props: { color: "primary", error: false, disabled: false },
          style: {
            "&:focus-within": {
              "& fieldset.MuiOutlinedInput-notchedOutline": {
                borderColor: sosPrimary[3],
              },
            },
          },
        },
      ],
    },
  },
});
