import { SET_SPLASH_SCREEN, SET_LOGIN_DATA,SET_USER_DATA, SET_LOCATION_DATA, LOGOUT_USER,SET_MARKER_COMMENTS } from "./actionType";

export const setSplashScreen = (payload, callback) => {
    return dispatch => {
        dispatch(setSplash(payload));
    }
}

export const setSplash = payload => {
    return dispatch => {
        dispatch({
            type: SET_SPLASH_SCREEN,
            payload,
        });
    };
};

export const setLoginData = payload => {
    return dispatch => {
        dispatch({
            type: SET_LOGIN_DATA,
            payload,
        });
    };
};

export const setUserData = payload => {
    return dispatch => {
        dispatch({
            type: SET_USER_DATA,
            payload,
        });
    };
};
export const logoutUser = payload => {
    return dispatch => {
        dispatch({
            type: LOGOUT_USER,
            payload,
        });
    };
};
export const setLocationData =payload=>{
    return dispatch =>{
        dispatch({
            type:SET_LOCATION_DATA,
            payload
        });
    }
}

export const setMarkerComment =payload=>{
    console.log(payload)
    return dispatch =>{
        dispatch({
            type:SET_MARKER_COMMENTS,
            payload
        });
    }
}