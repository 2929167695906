import React from 'react';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import './App.css';
import { router } from './routers';
let isMobile = true; // variable to check width
function App() {
	const [showMobileWarning, setShowMobileWarning] = useState(true);

	const handleWindowSizeChange = () => {
		isMobile = window.innerWidth < 770 ? true : false;
		setShowMobileWarning(isMobile);
	};
	// call your useEffect
	useEffect(() => {
		handleWindowSizeChange();
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, []);
	return (
		<>
			<div className='App'>
				<>
					<ToastContainer />
					<RouterProvider router={router} />
				</>
				<></>
			</div>
		</>
	);
}

export default App;

