// axios
import axios from 'axios'

export const apiEndpoint = `${process.env.REACT_APP_API_ENDPOINT}/${process.env.REACT_APP_API_VERSION}`
  
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
}

// create axios
const server = axios.create({
  baseURL: apiEndpoint,
  timeout: 90000,
  headers
})
server.interceptors.request.use((config)=>{
  let token = localStorage.getItem('userToken')
  if(token){
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})
server.interceptors.response.use(
  (res) => {
    return res.data
  },
  (err) => {
    return err.response.data
  },
)

export default server
