import React, { useEffect, useState, useRef } from "react";
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  ListItemAvatar,
  Avatar,
} from "@mui/material";
import { getMarkerCommnets } from "../../utils/urls";
import { formatDateTime, boldstring } from "../../utils/DateTime";
import { setMarkerComment } from "../../store/action/action";
import { ComponentWithRouterProp } from "../../constants/constant";
import { connect } from "react-redux";
import LoadingState from "../../common/Loader";
import { useLocation } from "react-router-dom";
import NoDataFound from "../../common/NoDataFound";

const ComponentList = ({
  marker,
  apiCallSuccess,
  GetMarkerApiSuccess,
  showImageFullScreen,
}) => {
  const [comment, setComment] = useState([]);
  const [firstRender, setFirstRender] = useState(true);
  const [loading, setLoading] = useState(false);
  const messagesEndRef = useRef(null);
  const mentionRef = useRef(null);
  const location = useLocation();

  const scrollToBottom = () => {
    if (comment.length > 0) {
      setFirstRender(false);
    }
    if (location?.state?.data?.type === "MENTION" && firstRender) {
      mentionRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    } else {
      messagesEndRef.current?.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    markerComment();
    if (firstRender) {
      scrollToBottom();
    }
  }, [marker, apiCallSuccess, JSON.stringify(comment)]);

  const markerComment = async () => {
    setLoading(true);
    if (marker) {
      let id = marker?._id;
      try {
        let response = await getMarkerCommnets(id);
        if (response.success) {
          scrollToBottom();
          setComment(response?.comments);
          GetMarkerApiSuccess();
        }
        setLoading(false);
      } catch (err) {
        console.log("err", err);
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <List>
        {comment.map((comment, index) => (
          <div
            key={comment?._id}
            ref={
              location?.state?.data?.comment_id?._id === comment?._id
                ? mentionRef
                : null
            }
            className={
              location?.state?.data?.comment_id?._id === comment?._id
                ? "mention-block"
                : null
            }
          >
            <ListItem style={{ padding: "6px 14px 0px 14px" }}>
              {comment.image ? (
                <ListItemAvatar style={{ minWidth: "unset" }}>
                  <Avatar
                    alt="User Image"
                    src={comment.image}
                    style={{
                      width: 30,
                      height: 30,
                      textTransform: "capitalize",
                      fontSize: 15,
                    }}
                  >
                    {comment?.createdBy?.full_name?.charAt(0)}
                  </Avatar>
                </ListItemAvatar>
              ) : (
                <ListItemAvatar style={{ minWidth: "unset" }}>
                  <Avatar
                    alt="User Icon"
                    src={comment?.createdBy?.profile_pic}
                    style={{
                      width: 30,
                      height: 30,
                      textTransform: "capitalize",
                      fontSize: 15,
                      marginRight: 15,
                    }}
                  >
                    {comment?.createdBy?.full_name?.charAt(0)}
                  </Avatar>
                </ListItemAvatar>
              )}
              <ListItemText
                primary={
                  <>
                    <Typography
                      variant="body1"
                      component="span"
                      className={
                        comment.attachments == "true" ? "" : "comment-text"
                      }
                    >
                      {comment.attachments == "true" ? (
                        <>
                          <img
                            src={comment.content}
                            alt="Attachment"
                            width="200px"
                            height="200px"
                            style={{ borderRadius: 10, objectFit: "cover" }}
                            onClick={() => showImageFullScreen(comment.content)}
                          />
                        </>
                      ) : (
                        boldstring(comment.content)
                      )}
                    </Typography>
                  </>
                }
              />
            </ListItem>
            <div className="time-passes-chat">
              {formatDateTime(comment.createdAt)}
            </div>
          </div>
        ))}
        {loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <LoadingState width="24px" height="24px" />
          </div>
        )}
        {!loading && comment.length === 0 && (
          <NoDataFound title="No comments found." marginTop={5} />
        )}
        <div ref={messagesEndRef} />
      </List>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setComments: (comment) => dispatch(setMarkerComment(comment)),
});
const mapStateToProps = ({ comment }) => ({
  comment: comment,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComponentWithRouterProp(ComponentList));
