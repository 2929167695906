import React from "react";
import NavBar from "../../common/NavBar/NavBar";
import {
  ComponentWithAuth,
  ComponentWithRouterProp,
} from "../../constants/constant";
import ConversationList from "./components/Conversation/ConversationList";
import { Navbar } from "react-chat-elements";
import { useNavigate } from "react-router-dom";
import PersonAddIcon from "@mui/icons-material/PersonAdd";

const ChatView = () => {
  let navigate = useNavigate();
  return (
    <div>
      <Navbar
        center={
          <div className="pl-2`" style={{ paddingLeft: 35 }}>
            Chats
          </div>
        }
        right={
          <div
            onClick={() => {
              navigate("new");
            }}
          >
            <PersonAddIcon
              style={{
                width: 35,
                height: 35,
                color: "#38678b",
              }}
            />{" "}
          </div>
        }
        type="light"
      />
      <div>
        <ConversationList />
      </div>
      <NavBar />
    </div>
  );
};
export default ComponentWithAuth(ComponentWithRouterProp(ChatView));
