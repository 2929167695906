import { RoomOutlined } from "@mui/icons-material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Box, Divider, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import FilterModel from "../../common/Filters/Filter";
import LoadingState from "../../common/Loader";
import ToastMessage from "../../common/Toast";
import { ComponentWithRouterProp } from "../../constants/constant";
import { lang } from "../../constants/lang";
import theme from "../../constants/themes/themes";
import { defaultMessage } from "../../constants/validationMessages";
import locationPin from "../../resources/LocationPinIcon.svg";
import SearchIcon from "../../resources/SearchIcon.svg";
import FieldImage from "../../resources/field.png";
import { setLocationData } from "../../store/action/action";
import {
  deleteLocation,
  getCurrentUser,
  getLocationList,
} from "../../utils/urls";
import "./location.css";
import {
  AddButton,
  MoreIcon,
  PlaceholderContent,
  PlaceholderText,
  Search,
  SearchIconWrapper,
  StyledInputBase,
} from "./style";
import ManageLocationDialog from "../../common/ManageLocationDialog/Index";
import PopUp from "../../common/PopUp/Index";

function LocationList(props) {
  const [locationData, setLocationData] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [openDialog, setDialogOpen] = useState(false);
  const [userId, setUserId] = useState("");
  const [currentUser, setCurrentUser] = useState("");
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  let locationListRef = useRef([]);
  const [locations, setLocation] = useState([]);
  let observer = useRef(null);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [hasMore, setHasMore] = useState(false);
  const [filters, setFilters] = useState({
    myLocation: false,
    invitedLocation: false,
  });
  useEffect(() => {
    getData();
    getAllLocations();
  }, []);

  //filter selection
  useEffect(() => {
    if (filters.myLocation || filters.invitedLocation) {
      getAllLocations("FILTER");
      props?.isFilter(false);
    } else {
      props?.isFilter(true);
    }
  }, [filters]);

  const getData = async () => {
    try {
      const response = await getCurrentUser();
      setUserId(response?.user?._id);
      setCurrentUser(response?.user?.user_name);
    } catch (err) {
      console.log(err);
    }
  };

  const getAllLocations = async (type, searchQuery) => {
    setLoading(true);
    let offSetToSEnd =
      type === "DELETE" || type === "FILTER" || type === "RESET" ? 0 : offset;

    try {
      const response = await getLocationList(
        limit,
        offSetToSEnd,
        searchQuery ? searchQuery : "",
        filters.myLocation,
        filters.invitedLocation,
      );
      const { location } = response.locations;
      if (type === "FILTER" || type === "RESET") {
        setLocation([]);
      }
      if (location && location.length > 0) {
        setLocation((prev) => [...prev, ...location]);
        setOffset((prev) => prev + limit);
      }
      setLoading(false);
      setHasMore(location?.length > 0);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          getAllLocations("", searchValue);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore],
  );

  const handleDebouceSearch = async (inputValue) => {
    getAllLocations("SEARCH", inputValue);
  };

  const debounceSearch = useCallback(debounce(handleDebouceSearch, 1000), []);

  const handleChange = (e) => {
    setLoading(true);
    setOffset(0);
    setLocation([]);
    setSearchValue(e.target.value);
    debounceSearch(e.target.value);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setDialogOpen(false);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };
  const handleDelete = async () => {
    try {
      setAnchorEl(null);
      setLoading(true);
      let response = await deleteLocation(locationData._id);
      if (response && response.success) {
        setOffset(0);
        setLocation([]);
        setSearchValue("");
        getAllLocations("DELETE");
        ToastMessage({
          type: response?.error ? "error" : "success",
          message: response.message,
        });
      }
    } catch (error) {
      ToastMessage({
        type: "error",
        message: defaultMessage,
      });
      console.log("err", error);
    }
  };
  const setFilterSelection = (type) => {
    setFilters({
      ...filters,
      [type]: !filters[type],
    });
  };
  return (
    <>
      <AppBar position="fixed" sx={{ top: "3rem" }}>
        <Box sx={{ margin: "1rem" }}>
          <Search>
            <SearchIconWrapper>
              <img src={SearchIcon} />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              onChange={handleChange}
              value={searchValue}
              inputProps={{ "aria-label": "search" }}
            />
          </Search>
        </Box>
      </AppBar>

      <>
        {locations?.length !== 0 && (
          <div style={{ marginBottom: 140, marginTop: 125 }}>
            {locations.map((locData, index) => {
              return (
                <Box
                  ref={locations?.length === index + 1 ? lastElementRef : null}
                  sx={{
                    textAlign: "left",
                    position: "relative",
                  }}
                  key={locData._id}
                >
                  {userId === locData.createdBy && (
                    <MoreIcon>
                      <IconButton
                        onClick={() => {
                          setLocationData(locData);
                          setDialogOpen(true);
                        }}
                        size="large"
                        sx={{
                          ml: 2,
                          borderRadius: "10%",
                          color: "#fff",
                          height: "1.2rem",
                          p: ".6rem",
                        }}
                        aria-controls={open ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                      >
                        <MoreHorizIcon style={{ fontSize: 30, marginTop: 5 }} />
                      </IconButton>
                    </MoreIcon>
                  )}

                  <Box
                    onClick={() => {
                      props.router.navigate("/locations/view/1", {
                        state: locData,
                      });
                    }}
                    component="img"
                    className="location-cover-image"
                    alt={locData.address}
                    src={locData.staticImage}
                  />
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mt: 1,
                    }}
                  >
                    <Typography
                      color="#707490"
                      variant="subhead2"
                      className="display-flex-align-center "
                    >
                      <img src={locationPin} style={{ paddingRight: 10 }} />
                      <div>{locData.name}</div>
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Typography color="#707491" variant="subhead3">
                        <a
                          target="_blank"
                          href={`http://www.google.com/maps/place/${locData.center_point[1]},${locData.center_point[0]}`}
                          className="location-hyperlink"
                        >
                          {locData.address}
                        </a>
                      </Typography>
                      <RoomOutlined
                        style={{
                          color: "#707491",
                          height: 25,
                        }}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ mb: 1 }}>
                    <Typography color="#000000" variant="subhead3">
                      {locData.description}
                    </Typography>
                  </Box>

                  <Divider />
                </Box>
              );
            })}

            {/* delete confirmation Dialogmodal */}
            {open && (
              <PopUp
                {...{
                  open,
                  content: null,
                  title: lang.DeleteLocationConfirmMessage,
                  handleCancel: handleClose,
                  handleConfirm: handleDelete,
                  confirmBtnText: lang.DeleteBtnText,
                }}
              />
            )}

            {/* Edit ,Delete Dialog modal */}
            {openDialog && (
              <ManageLocationDialog
                openDialog={openDialog}
                handleCloseDialog={handleCloseDialog}
                locationData={locationData}
                handleClick={handleClick}
              />
            )}
          </div>
        )}

        {loading && (
          <div
            style={{
              marginTop: offset === 0 ? "150px" : "0px",
              marginBottom: 70,
            }}
          >
            <LoadingState color={theme.palette.primary.main} />
          </div>
        )}

        <AddButton
          onClick={() => {
            props.router.navigate("/locations/add");
          }}
          style={{
            background: "linear-gradient(153deg, #1b2046 0%, #38678b 100%)",
            color: "white",
          }}
          fullWidth
          variant="contained"
          color="inherit"
        >
          {lang.addLocation}
        </AddButton>

        {!loading && locations?.length === 0 && !searchValue && (
          <div>
            <PlaceholderContent>
              <PlaceholderText>
                <Typography variant="title_xlarge" color="#1B2046">
                  Welcome,{currentUser}
                </Typography>
                <Typography variant="label_large" color="#707491">
                  Let's add a location
                </Typography>
              </PlaceholderText>
              <img src={FieldImage} alt="No locations Added" />
            </PlaceholderContent>{" "}
          </div>
        )}
        {!loading && locations?.length === 0 && searchValue && (
          <div>
            <PlaceholderContent>
              <PlaceholderText>
                <Typography variant="label_large" color="#707491">
                  {lang.noLocationFound}
                </Typography>
              </PlaceholderText>
              <img src={FieldImage} alt="No locations Added" />
            </PlaceholderContent>
          </div>
        )}
      </>
      {/* Filter modal */}
      <FilterModel
        filter={props?.filter}
        closeFilter={props.closeFilter}
        selectedFilter={(val) => setFilterSelection(val)}
        filters={filters}
        applyFilter={async () => {
          props.closeFilter(false);
        }}
        resetFilter={() => {
          setOffset(0);
          setFilters({
            myLocation: false,
            invitedLocation: false,
          });
          props.closeFilter(false);
          props.isFilter(true);
          getAllLocations("RESET");
        }}
      />
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  setLocationDataAction: (payload) => dispatch(setLocationData(payload)),
});
const mapStateToProps = ({ locationData }) => ({
  locationData: locationData,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComponentWithRouterProp(LocationList));
