import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import * as turf from "@turf/turf";
import { isEmpty } from "lodash";
import "mapbox-gl/dist/mapbox-gl.css";
import React, { useCallback, useEffect, useState } from "react";
import ReactMapboxGl, { Feature, Layer, Marker } from "react-mapbox-gl";
import DrawControl from "react-mapbox-gl-draw";
import { useNavigate } from "react-router-dom";
import LocationMarker from "../../resources/location.png";
import { getMarkerIconType } from "../../utils/functions";
import { calcBoundsFromCoordinates } from "../../utils/helper";
import "./mapview.css";

const Map = ReactMapboxGl({
  attributionControl: false,
  accessToken: process.env.REACT_APP_MAPBOX_ACCESSKEY,
});
const GeoJSONLayer = ({ geoJson, showHideMapItems }) => {
  return (
    <>
      {geoJson.map((data, index) => (
        <Layer
          type="fill"
          key={index}
          layout={{
            visibility: showHideMapItems ? showHideMapItems?.satellite ? "visible" : "none": 'visible',
          }}
          paint={{
            "fill-color":
              data.properties && data.properties.fill
                ? data.properties.fill
                : "#fff",
            "fill-opacity":
              data.properties && data.properties["fill-opacity"]
                ? data.properties["fill-opacity"]
                : 0,
            "fill-outline-color":
              data.properties && data.properties.stroke
                ? data.properties.stroke
                : "#fff",
          }}
        >
          <Feature key={index} coordinates={data.geometry.coordinates} />
        </Layer>
      ))}
    </>
  );
};
export function MapView({
  height = "100vh",
  markers = [],
  control = false,
  marker = false,
  zoom = 16,
  bearing = 0,
  pitch = 0,
  current_loc,
  handleAddress,
  createdBoundary,
  onChangeCurrentLocation,
  setDrawnLayer = [],
  loading = true,
  polygonCoordinates,
  setMarkers,
  defaultFitBounds = false,
  hasFitBound = true,
  markerImage = LocationMarker,
  disableZoomScroll = false,
  marker_point,
  navigationEnabled = true,
  width = "100%",
  viewType,
  showHideMapItems,
  mapType,
}) {
  const image = new Image();
  image.src = markerImage;
  const images = ["markers", image];
  let initialLatLng = useState();
  const [clickedCoordinates, setClickedCoordinates] = useState(null);
  const [area, setArea] = useState();
  const [fitBounds, setFitBounds] = useState({});
  const [markerscor, setMarkersCor] = useState([]);
  const [draggable, setDraggable] = useState(true);
  let [mapFitBoundHas, setHasFitBound] = useState(hasFitBound);
  let [mapZoom, setMapZoom] = useState(zoom);
  const [marker_coordinate, setMarkerPoint] = useState(current_loc);
  const navigate = useNavigate();
  const handleMapClick = (map, event) => {
    const { lngLat } = event;
    const isWithinPolygon = turf.booleanPointInPolygon(
      turf.point([lngLat.lng, lngLat.lat]),
      turf.polygon(polygonCoordinates),
    );
    if (isWithinPolygon) {
      const newMarker = {
        id: 1,
        coordinates: lngLat,
      };
      if (setMarkers) setMarkers(lngLat);
      // setMarkersCor([newMarker]);
    }
  };
  let mapRef = React.useRef(null);
  const mapDrawRef = useCallback((drawMapBoxRef) => {
    if (drawMapBoxRef !== null) {
      if (setDrawnLayer && setDrawnLayer.length > 0) {
        if (drawMapBoxRef.draw) {
          fitBoundary(setDrawnLayer[0]);
          drawMapBoxRef.draw.add(setDrawnLayer[0]);
        }
      }
    }
  }, []);

  useEffect(() => {
    if (!isEmpty(defaultFitBounds)) {
      setFitBounds(defaultFitBounds);
    } else {
      setFitBounds({});
    }
  }, [defaultFitBounds]);

  // feature reference
  const fitBoundary = (layers) => {
    var coordinates = layers?.geometry?.coordinates[0];
    var bounds = calcBoundsFromCoordinates(layers);
    setFitBounds(bounds);
  };

  useEffect(() => {
    if (area) {
      let { location, ...rest } = area;
      createdBoundary(rest);
    }
  }, [area]);

  const onDrawCreate = async ({ features }) => {
    var center = turf.center(features[0].geometry);
    const calculateArea = turf.area(features[0].geometry);
    onChangeCurrentLocation(center.geometry.coordinates);
    setArea((prevState) => ({
      ...prevState,
      area: calculateArea,
      location: center.geometry.coordinates,
      boundary: features[0],
      center_point: center.geometry.coordinates,
    }));
  };

  const onDrawUpdate = ({ features }, rest) => {
    var center = turf.center(features[0].geometry);
    const calculateArea = turf.area(features[0].geometry);
    onChangeCurrentLocation(center.geometry.coordinates);
    setArea((prevState) => ({
      ...prevState,
      area: calculateArea,
      boundary: features[0],
      location: center.geometry.coordinates,
      center_point: center.geometry.coordinates,
    }));
  };

  const clusterMarker = (coordinates) => (
    <Marker coordinates={coordinates}></Marker>
  );
  const onMarkerDragStart = useCallback((event) => {
    const { lngLat } = event;
    mapRef.dragPan.disable();
    setMapZoom(mapRef.getZoom());
    setHasFitBound(false);
  }, []);
  // For future reference
  const onMarkerDrag = useCallback((event) => {
    const { lngLat } = event;
    if (polygonCoordinates) {
      const isWithinPolygon = turf.booleanPointInPolygon(
        turf.point([lngLat.lng, lngLat.lat]),
        turf.polygon(polygonCoordinates),
      );
    }
  }, []);

  const onMarkerDragEnd = useCallback((event) => {
    const { lngLat } = event;
    mapRef.dragPan.enable();
    if (polygonCoordinates) {
      const isWithinPolygon = turf.booleanPointInPolygon(
        turf.point([lngLat.lng, lngLat.lat]),
        turf.polygon(polygonCoordinates),
      );
      if (!isWithinPolygon) {
        setDraggable(false);
        setTimeout(() => {
          setDraggable(true);
        }, 500);
        return onChangeCurrentLocation(current_loc);
      } else {
        setMarkerPoint([lngLat.lng, lngLat.lat]);
        setHasFitBound(false);
        setMapZoom(mapRef.getZoom());
        setDraggable(true);
        return onChangeCurrentLocation([lngLat.lng, lngLat.lat]);
      }
    } else {
      onChangeCurrentLocation([lngLat.lng, lngLat.lat]);
    }
  }, []);

  useEffect(() => {
    if(showHideMapItems && Object.values(showHideMapItems).includes(false)){
    setHasFitBound(false)
    }
  }, [showHideMapItems])
  

  return (
    <div>
      <Map
        onStyleLoad={(map) => {
          mapRef = map;
        }}
        style="mapbox://styles/mapbox/satellite-streets-v11" // eslint-disable-line
        containerStyle={{
          top: 0,
          height: height,
          width: width,
          marginLeft: viewType === "notification" ? "-16px" : "",
        }}
        bearing={bearing ? [bearing] : [0]}
        pitch={pitch ? [pitch] : [0]}
        zoom={mapType != "Marker" ? mapZoom ? [mapZoom] : [60]: undefined} //to avoid zoom in and zoom, when selecting the markers
        center={current_loc}
        {...(fitBounds && mapFitBoundHas && !loading
          ? {
              fitBounds: !isEmpty(fitBounds) ? fitBounds : 0,
              fitBoundsOptions: {
                padding: 10,
              },
            }
          : {})}
        onClick={handleMapClick}
      >
        {clickedCoordinates && (
          <Marker
            coordinates={[clickedCoordinates.lng, clickedCoordinates.lat]}
            anchor="bottom"
          >
            <img width={40} height={40} src={markerImage} />
          </Marker>
        )}
        {control && (
          <DrawControl
            position="bottom-left"
            onDrawDelete={() => {
              setArea(null);
              setFitBounds(0);
            }}
            ref={mapDrawRef}
            styles={[
              {
                id: "gl-draw-polygon-fill",
                type: "fill",
                paint: {
                  "fill-color": "#68E6F8",
                  "fill-outline-color": "#09c0dc",
                  "fill-opacity": 0.4,
                },
              },
              //*** HERE YOU DEFINE POINT STYLE *** //
              {
                id: "gl-draw-point",
                type: "circle",
                paint: {
                  // "circle-radius": 5,
                  "circle-color": "#3DB3C0",
                  "circle-opacity": 0,
                  "circle-stroke-width": 3,
                  "circle-stroke-color": "#fff",
                },
              }, //**********************************//
              {
                id: "gl-draw-polygon-stroke-active",
                type: "line",
                layout: {
                  "line-cap": "round",
                  "line-join": "round",
                },
                paint: {
                  "line-color": "#7DF9FB",
                  "line-width": 2.5,
                },
              },
              {
                id: "gl-draw-polygon-and-line-vertex-active",
                type: "circle",
                filter: [
                  "all",
                  ["==", "meta", "vertex"],
                  ["==", "$type", "Point"],
                  ["!=", "mode", "static"],
                ],
                paint: {
                  // "circle-radius": 5,
                  "circle-color": "#3DB3C0",
                },
              },
            ]}
            displayControlsDefault={false}
            controls={{
              polygon: true,
              trash: true,
            }}
            onDrawCreate={onDrawCreate}
            onDrawUpdate={onDrawUpdate}
          />
        )}

        {markers &&
          markers.length > 0 &&
          markers.map((marker, index) => (
            <div key={marker.id}>
              {marker.marker_type === "MANUAL" ? (
                <Marker
                  key={marker.id}
                  coordinates={marker}
                  anchor="center"
                  offset={10 * (index - (markers.length - 1) / 2)}
                  onClick={() => {
                    navigate("markerdetails", { state: marker });
                  }}
                  style={{
                    display: showHideMapItems?.markers ? 
                    (!showHideMapItems?.drone && marker?.type ==="Drone" ? "none": "block") 
                    : (showHideMapItems?.drone && marker?.type ==="Drone" ? "block": "none"),
                  }}
                >
                  {getMarkerIconType(marker?.type, "40px", "40px")}
                </Marker>
              ) : (
                <>
                  {marker.satellite_geo && (
                    <GeoJSONLayer
                      geoJson={marker?.satellite_geo}
                      showHideMapItems={showHideMapItems}
                    />
                  )}
                </>
              )}
            </div>
          ))}

        {marker_point && (
          <Layer
            type="symbol"
            images={images}
            layout={{
              "icon-image": "markers",
              "icon-size": [
                "interpolate",
                ["linear"],
                ["zoom"],
                10,
                1,
                15,
                0.5,
              ],
              "icon-allow-overlap": true,
              "icon-anchor": "bottom",
            }}
          >
            <Feature
              draggable={draggable}
              onDrag={onMarkerDrag}
              onDragEnd={onMarkerDragEnd}
              coordinates={marker_coordinate}
              onDragStart={onMarkerDragStart}
            />
          </Layer>
        )}
        {current_loc && navigationEnabled && (
          <Layer
            type="symbol"
            images={images}
            layout={{
              "icon-image": "markers",
              "icon-size": 1,
              "icon-allow-overlap": true,
              "icon-anchor": "bottom",
            }}
          >
            <Feature
              draggable={draggable}
              onDrag={onMarkerDrag}
              onDragEnd={onMarkerDragEnd}
              coordinates={current_loc}
              onDragStart={onMarkerDragStart}
            />
          </Layer>
        )}
        {polygonCoordinates && (
          <Layer
            type="fill"
            paint={{
              "fill-color": "#68E6F8",
              "fill-outline-color": "#68E6F8",
              "fill-opacity": 0.18,
            }}
          >
            <Feature coordinates={polygonCoordinates} />
          </Layer>
        )}
      </Map>
    </div>
  );
}
