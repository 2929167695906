import { SET_SPLASH_SCREEN, SET_LOGIN_DATA , SET_USER_DATA, SET_LOCATION_DATA, LOGOUT_USER,SET_MARKER_COMMENTS} from "./action/actionType";

const INITIAL_STATE = {
    isAuth: false,
    splash:true,
    userToken: "",
    userData:[],
    comment:[],
}

// Use the initialState as a default value
export default function appReducer(state = INITIAL_STATE, action) {
    // The reducer normally looks at the action type field to decide what happens
    switch (action.type) {
        // Do something here based on the different types of actions
        case SET_SPLASH_SCREEN:
            return {
                ...state,
                splash: action.payload,
            };
        case SET_LOGIN_DATA:
            return {
                ...state,
                isAuth:true,
                userToken: action.payload,
            };
            
        case SET_USER_DATA:
            return {
                ...state,
                userData: action.payload
            };

        case SET_LOCATION_DATA:
            return {
                ...state,
                locationData:action.payload
            }
        case LOGOUT_USER:
            return INITIAL_STATE
        case SET_MARKER_COMMENTS:
            return {
                ...state,
                comment:action.payload
            }
        default:
            // If this reducer doesn't recognize the action type, or doesn't
            // care about this specific action, return the existing state unchanged
            return state
    }
}