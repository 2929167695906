import React, { useContext, useEffect, useState } from "react";
import { ChatItem } from "react-chat-elements";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../constants/routes";
import { doc, onSnapshot, getDoc } from "firebase/firestore";
import { db } from "../../../../ firebase";
import { AuthContext } from "../../context/AuthContext";
import LoadingState from "../../../../common/Loader";
import moment from "moment";
import NoDataFound from "../../../../common/NoDataFound";
import { lang } from "../../../../constants/lang";
import theme from "../../../../constants/themes/themes";
import ToastMessage from "../../../../common/Toast";
import { defaultMessage } from "../../../../constants/validationMessages";
import _ from "lodash";

export default function ConversationList() {
  const [chats, setChats] = useState([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    currentUser?.uid && getChats();
  }, [currentUser?.uid]);

  const getChats = async () => {
    const unsub = await onSnapshot(
      doc(db, "userChats", currentUser.uid),
      (doc) => {
        setChats(doc.data());
        setLoading(false);
      },
      (error) => {
        ToastMessage({
          type: "error",
          message: defaultMessage,
        });
        console.log("error", error);
      },
    );
  };

  return (
    <div style={{ marginTop: 65 }}>
      {chats && (
        <>
          {Object.entries(chats)
            ?.sort((a, b) => b[1].date - a[1].date)
            .map((chat, index) => (
              <div key={index}>
                {chat[1].lastMessage && (
                  <div
                    className="userChat"
                    key={index}
                    // onClick={() => handleSelect(chat[1].userInfo)}
                  >
                    <ChatItem
                      avatar={
                        chat[1].userInfo?.profilePhoto
                          ? chat[1].userInfo?.profilePhoto
                          : `https://ui-avatars.com/api/?name=${chat[1].userInfo.name[0]}`
                      }
                      alt={chat[1]?.userInfo?.name[0]}
                      title={
                        <div>
                          <div className="chat-name">
                            {chat[1]?.userInfo?.name}
                          </div>

                          <div className="chat-user-name">
                            {`@${chat[1]?.userInfo?.userName}`}
                          </div>
                        </div>
                      }
                      subtitle={chat[1].lastMessage.text}
                      date={moment(chat[1]?.date?.toDate()).format(
                        "YYYY-MM-DD HH:mm:ss",
                      )}
                      dateString={moment(chat[1]?.date?.toDate()).format(
                        "MMM DD, h:mm A",
                      )}
                      unread={chat[1]?.isNew ? true : false}
                      onClick={() => {
                        navigate(routes.CONVERSATION, {
                          state: {
                            conversation_id: chat[1].chat_id,
                            chatUser: chat[1].userInfo,
                          },
                        });
                      }}
                    />
                  </div>
                )}
              </div>
            ))}
        </>
      )}

      {_.isEmpty(chats) && !loading && (
        <div>
          <NoDataFound title={lang.noChatsFound} />
        </div>
      )}
      {loading && (
        <div style={{ marginTop: 50 }}>
          <LoadingState color={theme.palette.primary.main} />
        </div>
      )}
    </div>
  );
}
