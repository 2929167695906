/** @format */
// let apiVersion = process.env.REACT_APP_API_URL;

import Server from "./interceptor";
import axios from "axios";

/**
 * Function to handle Singup form
 * @param {*} data
 * @returns
 */
export const handleSignup = (data) => {
  data.email = data.email.toLowerCase();
  return Server({
    method: "post",
    url: "auth/register",
    data,
  });
};
/**
 * Function to handle login
 * @param {*} data
 * @returns
 */
export const handleLogin = (data) => {
  return Server({
    method: "post",
    url: "auth/login",
    data,
  });
};
export const handleForgetPassword = (data) => {
  return Server({
    method: "post",
    url: "auth/forgot-password",
    data,
  });
};

/**
 * Function to to get current User
 * @returns
 */
export const getCurrentUser = async () => {
  return Server({
    method: "get",
    url: "auth/current_user",
  });
};

/**
 * Function to handle forgot password
 * @param {*} data
 * @returns
 */
export const handleForgot = (data) => {
  return Server({
    method: "post",
    url: "auth/forgot_password",
    data,
  });
};

/**
 * Function to handle reset password
 * @param {*} data
 * @returns
 */
export const handleReset = ({ password, token }) => {
  return Server({
    method: "post",
    url: `auth/reset_password?token=${token}`,
    data: { password },
  });
};

export const getAddressFromCoordinates = (lat, long) => {
  let api = "https://api.mapbox.com/geocoding/v5/mapbox.places/";
  let accessToken =
    "pk.eyJ1IjoiZHBlcnJlYXVsdCIsImEiOiJjbDJyaWN0Y2swYXIyM21vM2o5YWswemM4In0.w5FRTeVjAYaPJsNWUCd7mA";
  let url = `${api}${lat},${long}.json?access_token=${accessToken}`;
  return axios
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
};
/**
 * Function to handle Singup form
 * @param {*} data
 * @returns
 */
export const handleEditProfileData = (data) => {
  data.email = data.email.toLowerCase();
  return Server({
    method: "put",
    url: `user/${data.userId}`,
    data,
  });
};

/**
 * Function to Add Location
 * @param {*} data
 * @returns
 */

export const getLocationList = (limit, offset, data, myLocation = false, invitedLocation = false) => {
  return Server({
    method: "get",
    url: `location/myfeed?limit=${limit}&offset=${offset}&search=${data}&createdBy=${myLocation}&isInvited=${invitedLocation}`,
  });
};

/**
 * Function to get LocationList
 * @param {*}
 * @returns
 */

export const addLocation = (data) => {
  return Server({
    method: "post",
    url: "location",
    data,
  });
};

export const updateLocation = (data) => {
  return Server({
    method: "put",
    url: `location/${data.locationId}`,
    data,
  });
};

export const deleteLocation = (id) => {
  return Server({
    method: "delete",
    url: `location/${id}`,
  });
};

export const UploadFile = (data) => {
  return Server({
    headers: {
      "Content-Type": "multipart/form-data",
    },
    method: "post",
    url: `upload/File`,
    data,
  });
};

export const addMarker = (data) => {
  return Server({
    method: "post",
    url: `marker`,
    data,
  });
};

/**
 *
 * @param {*} data marker data to update
 * @param {id} id marker id
 * @returns response
 */

export const updateMarker = (data, id) => {
  return Server({
    method: "put",
    url: `marker/${id}`,
    data,
  });
};

export const getMarker = async (id) => {
  return Server({
    method: "get",
    url: `marker/location/${id}`,
  });
};

export const getMarkerById = async (id) => {
  return Server({
    method: "get",
    url: `marker/${id}`,
  });
};

export const deleteMarkerById = async (id) => {
  return Server({
    method: "delete",
    url: `marker/${id}`,
  });
};

export const markerCommnets = async (data) => {
  return Server({
    method: "post",
    url: `comment`,
    data,
  });
};

export const getMarkerCommnets = async (id) => {
  return Server({
    method: "get",
    url: `comment/${id}`,
  });
};

export const getUserList = async (keyword) => {
  return Server({
    method: "get",
    url: `user/search/${keyword}`,
  });
};

export const getNotificationList = async (count, pageNo, createdBy=false, isInvited = false) => {
  return Server({
    method: "get",
    url: `notification/my?limit=${count}&offset=${pageNo}&createdBy=${createdBy}&isInvited=${isInvited}`,
  });
};

export const handleChangePassword = async (data) => {
  return Server({
    method: "post",
    url: `auth/change_password`,
    data,
  });
};
