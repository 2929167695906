import diseaseIcon from "../assets/Icons/disease.png";
import waterIcon from "../assets/Icons/water.png";
import otherIcon from "../assets/Icons/other.png";
import pestIcon from "../assets/Icons/pest.png";
import taskIcon from "../assets/Icons/task.png";
import scoutIcon from "../assets/Icons/scout.png";
import droneIcon from "../assets/Icons/droneIcon.png";
import droneIconWhite from "../assets/Icons/droneIconWhite.png";
import drone from "../assets/Icons/drone.png";
import satelliteIcon from "../assets/Icons/SatelliteIcon.png";

export const DISEASE_ICON = diseaseIcon;
export const WATER_ICON = waterIcon;
export const OTHER_ICON = otherIcon;
export const PEST_ICON = pestIcon;
export const TASK_ICON = taskIcon;
export const SCOUT_ICON = scoutIcon;
export const DRONE_ICON = droneIcon;
export const DRONE_ICON_WHITE = droneIconWhite;
export const DRONE = drone;
export const SATELLITE_ICON = satelliteIcon;
