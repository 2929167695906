import { createTheme } from "@mui/material/styles";
import { themeAppBar } from "./appbar";
import { themeButtons } from "./buttons";
import { themePalette } from "./colors";
import { themeDropDown } from "./dropdown";
import { themeInputs } from "./input";
import { themeTypography } from "./typography";
const theme = createTheme(
  themeTypography,
  themeButtons,
  themeInputs,
  themeAppBar,
  themeDropDown,
  themePalette
);

export default theme;