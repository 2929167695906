export const lang = {
  pleaseEnter: (text) => `Please enter ${text}`,
  enter: (text) => `Enter ${text}`,
  welcomeToPixelar: "Welcome to Pixelar",
  chooseOptionToLoginOrSignup:
    "choose an option below to login or sign up with an email",
  emailAddress: "Email Address",
  email: "Email",
  submit: "Submit",
  password: "Password",
  forgotPassword: "Forgot Password?",
  forgotPasswordBtnText: "Continue",
  havePassword: "  Don't have a Pixelar account yet? ",
  welcomeBack: "Hello! Welcome back",
  signInToPixelar: "Sign in to Pixelar",
  signIn: "Sign in",
  dontHaveAccount: "Don't have a Pixelar account yet?",
  createAccount: "Create an Account",
  userName: "Username",
  fullName: "Full Name",
  phoneNumber: "Phone Number",
  role: "Role",
  alreadyHaveAccount: "Already have a Pixelar account?",
  signup: "Sign up",
  backToSignIn: "Back to Sign In",
  enterRecoveryEmail:
    "Enter the email you registered with your Pixelar account, later you will be receive an email.",
  resetPassword: "Reset Password",
  enterYourPassword: "Enter your new password below.",
  confirmPassword: "Confirm Password",
  loginSuccess: "Login Successfully",
  DeleteLocationConfirmMessage: "Do you want to delete this location?",
  LogoutConfirmMessage: "Are you sure do you want to logout ?",
  addLocationSuccess: "Location Added Successfully",
  EditLocation: "Edit Location",
  DeleteLocation: "Delete Location",
  DeleteBtnText: "Delete",
  LogoutBtnText: "Logout",
  CancelBtnText: "Cancel",
  EditProfile: "Edit profile",
  name: "Name",
  phone: "Phone",
  ChangeProfilePhoto: "Change Profile Photo",
  LogOut: "Logout",
  nameRequiredMessage: "Please enter valid name",
  addressRequiredMessage: "Please enter valid address",
  typeRequireMessage: "Please select type",
  descriptionRequireMessage: "Please enter valid description",
  AddMarkerSuccess: "Marker Added Successfully",
  UpdateMarkerSuccess: "Marker Updated Successfully",
  DroneNotification: "Drone Notification",
  EditMarker: "Edit Marker",
  DeleteMarker: "Delete Marker",
  DorneNotificationText:
    "This is a close up view of that problem area from the north field.",
  Notification: "Notifications",
  NoNotificatonsFound: "No Notifications Found.",
  comments: "Comments",
  noLocationFound: "No location found",
  addLocation: "+ Add Location",
  newPassword: "New Password",
  confirmNewPassword: "Confirm New Password",
  changePassword: "Change Password",
  noUserFound: "No User Found.",
  noChatsFound: "No Chats Found.",
  somethingWentWrong: "Oops! Something went wrong",
  pageDoesNotExist: "The page you have requested does not exist!",
  backToHome: "Back to home",
  pageRefreshTryAgain: "You may refresh the page or try again later.",
  userRegisteredSuccess: "User Registered Successfully.",
  emailInviteBody:
    "Join me on Pixelar Farmportal where we can chat and collaborate on our locations. Sign up with me at https://app.farmportal.app",
  emailInviteSubject:  "Join Me on Pixelar FarmPortal!",
  satelliteSubscription:"Subscribe to Satellite Data for this Field?",
  FilterTitle:"Filters",
  ResetFilter:"Reset",
  ApplyFilter: "Apply",
  SubscribeDialogTitle: "Confirm Subscribe",
  SubscribeDialogContent: "Please confirm you’d like to receive 3m Planet data at $1 per Acre per Year subscription for this field?",
  subscribeHere:
    "subscribe here",
  registationSuccess: "Registration Successful",
  invitationMessage: "has invited you to this location",
  paymentMessage: "Welcome to Pixelar FarmPortal! Please complete your subscription that includes a 30-day free trial",
};
