import { MuiTelInput, matchIsValidTel } from "mui-tel-input";
import React from "react";

export default function PhoneInput(props) {
  const [phone, setPhone] = React.useState(props?.value);

  const handleChange = (newPhone) => {
    setPhone(newPhone);
    props.onPhoneChange(newPhone);
    props.setIsValidPhoneNumber && props.setIsValidPhoneNumber(true);
  };
  return (
    <MuiTelInput
      fullWidth
      value={phone}
      onChange={handleChange}
      onBlur={props.handlePhoneBlur}
      error={props.error}
      name="phone"
      placeholder="+1 Enter Phone Number"
      variant={props.variant}
      focusOnSelectCountry
    />
  );
}
