import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import { lang } from "../../constants/lang";
import { useNavigate } from "react-router-dom";

export default function ManageLocationDialog({
  openDialog,
  handleCloseDialog,
  locationData,
  handleClick,
}) {
  const navigate = useNavigate();
  return (
    <Dialog
      open={openDialog}
      onClose={handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{ sx: { position: "fixed", bottom: 0 } }}
      sx={{
        "&.MuiPaper-root,.MuiDialog-paper": {
          margin: "auto",
          width: "100%",
          backgroundColor: "transparent",
        },
      }}
    >
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "200px",
          "&.MuiDialogActions-root>:not(:first-of-type)": {
            marginLeft: 0,
          },
        }}
      >
        <Button
          variant="containedLight"
          className="positive-button"
          onClick={() => {
            navigate("/locations/add", {
              state: locationData,
            });
          }}
        >
          {lang.EditLocation}
        </Button>
        <Button
          variant="containedLight"
          className="negative-button"
          onClick={handleClick}
          autoFocus
        >
          {lang.DeleteLocation}
        </Button>
        <Button
          variant="containedLight"
          className="cancel-button"
          onClick={handleCloseDialog}
        >
          {lang.CancelBtnText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
