import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@mui/material";
import { Typography } from "@mui/material/node";
import { ErrorMessage, Field, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import AppBars from "../../common/AppBar/Index";
import { MapView } from "../../common/MapView/MapView";
import NavBar from "../../common/NavBar/NavBar";
import ToastMessage from "../../common/Toast";
import {
  ComponentWithAuth,
  ComponentWithRouterProp,
} from "../../constants/constant";
import { lang } from "../../constants/lang";
import { routes } from "../../constants/routes";
import {
  addLocation,
  getAddressFromCoordinates,
  updateLocation,
} from "../../utils/urls";
import "./addlocation.css";

// For future reference
// import * as geoLocator from "geolocator";

// geoLocator.config({
//   language: "en",
//   google: {
//       version: "3",
//       key: "AIzaSyDUv5BZ-Tsvss-a37SKG4llbgB77_Jhxns"
//   }
// })
import { useGeolocated } from "react-geolocated";
import UserSelectComponent from "../../common/UserSelectComponent";
import PopUp from "../../common/PopUp/Index";
const crops = [
  "Alfalfa",
  "Barley",
  "Corn",
  "Cotton",
  "Hay",
  "Oats",
  "Peanut",
  "Potatoes",
  "Rice",
  "Sorghum",
  "Soybeans",
  "Sugarcane",
  "Sunflowers",
  "Tobacco",
  "Wheat",
  "Mixed",
  "Others",
];

function AddLocation() {
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [coordinates, setCoordinates] = useState(
    state && state.center_point ? state.center_point : [0, 0],
  );
  const [mapboxData, setMapboxData] = useState();
  const formRef = useRef({});
  const navigate = useNavigate();
  const [boundary, setBoundary] = useState(state ? true : false);
  const [invitedUsers, setInvitedUsers] = useState(
    state ? state.invitedUsers : [],
  );
  const locationId = state?._id;

  const [satelliteStatus, setSatelliteStatus] = useState(state ? state.is_satellite_enable : false);
  const [open, setOpen] = React.useState(false);
  const [satelliteToggleCliked, setSatelliteToggleCliked] = useState(false);

  const handleCancel = () => {
    setOpen(false);
    setSatelliteStatus(!satelliteStatus)

  };

  const handleConfirm = () => {
    setOpen(false);
  };

  // For future reference
  // const { coords, isGeolocationAvailable, isGeolocationEnabled } =
  // useGeolocated({
  //     positionOptions: {
  //         enableHighAccuracy: true,
  //     },
  //     watchPosition: true,
  //     userDecisionTimeout: 5000,
  // });
  // const setUpGeoLocation = ()=>{
  //   // console.log(coords, isGeolocationAvailable,isGeolocationEnabled)
  //   if(coords && isGeolocationAvailable && isGeolocationEnabled){
  //     setCoordinates([coords.longitude, coords.latitude]);
  //   }
  // }

  useEffect(() => {
    window.scrollTo(0, 0);
    if (!state) {
      // For future reference
      // var options = {
      //   enableHighAccuracy: true,
      //   timeout: 5000,
      //   maximumWait: 10000, // max wait time for desired accuracy
      //   maximumAge: 0, // disable cache
      //   desiredAccuracy: 30, // meters
      //   fallbackToIP: true, // fallback to IP if Geolocation fails or rejected
      //   addressLookup: true, // requires Google API key if true
      //   timezone: true, // requires Google API key if true
      //   map: "map-canvas", // interactive map element id (or options object)
      //   staticMap: true, // get a static map image URL (boolean or options object)
      // };
      // geoLocator.locate(options, function (err, location) {
      //   if (err) return console.log(err);
      //   console.log(location);
      // });
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCoordinates([position.coords.longitude, position.coords.latitude]);
        },
        (error) => console.log(error),
        {
          enableHighAccuracy: true,
        },
      );
      // setUpGeoLocation()
    }
  }, []);

  useEffect(() => {
    getAddress();
  }, [coordinates]);

  const handleAddress = (address) => {
    console.log(
      "🚀 ~ file: AddLocation.js:43 ~ handleAddress ~ address:",
      address,
    );
  };
  /**
   * Funciton to get coordinates from mapbox
   * @param {*} coordinates
   */
  const handleCoordinates = (coordinates) => {
    // let { boundary } = coordinates
    // let rightHandRuleCoordinates = [convertToRightHandRule(boundary[0])]
    // coordinates.boundary = rightHandRuleCoordinates
    setMapboxData(coordinates);
  };
  /**
   * Function to get address from coordinates
   */
  const getAddress = async () => {
    let response = await getAddressFromCoordinates(...coordinates);
    if (response?.features[0]?.place_name) {
      formRef.current.setFieldValue("address", response.features[0].place_name);
    }
  };
  /**
   * Function to handle signup form
   * @param {*} formData
   */
  const handleLocationForm = async (formData) => {
    try {
      setLoading(true);
      let response;
      if (!state) {
        if (!mapboxData || mapboxData.length === 0) {
          setLoading(false);
          return toast["error"](
            "Please create valid boundary or Please complete the boundary Creation ",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            },
          );
        }
        if (invitedUsers && invitedUsers.length > 0) {
          formData.invitedUsers = invitedUsers.map((user) => user._id);
        }
        response = await addLocation({ ...formData, ...mapboxData });
        setLoading(false);
        ToastMessage({
          type: response?.success ? "success" : "error",
          message: response?.success
            ? lang.addLocationSuccess
            : response.message,
        });
      } else {
        if (invitedUsers && invitedUsers.length > 0) {
          formData.invitedUsers = invitedUsers.map((user) => user._id);
        }else{
          formData.invitedUsers = []
        }
        response = await updateLocation({
          ...formData,
          ...mapboxData,
          locationId,
        });
        setLoading(false);
        ToastMessage({
          type: response?.success ? "success" : "error",
          message: response.message,
        });
      }

      if (response?.success) {
        navigate(routes.LOCATION);
      }
    } catch (error) {
      console.log("🚀 ~ file: AddLocation.js:121 ~ addlocation~ error:", error);
    }
  };

  useEffect(() => {
    if(satelliteStatus && satelliteToggleCliked){
      setOpen(true);
    }
  }, [satelliteStatus])
  
  return (
    <Box>
      <AppBars
        title={state ? "Edit Location" : "Add Location"}
        hasAction={true}
        action={() => {
          if (!loading) formRef.current.submitForm();
        }}
      />

      {loading && (
        <Box
          sx={{
            position: "absolute",
            zIndex: 999,
            left: 0,
            right: 0,
            top: "50%",
            bottom: 0,
          }}
        >
          <CircularProgress color="inherit" />
        </Box>
      )}
      <div
        className="responsive-margin-top-header"
        style={{ position: "relative" }}
      >
        <Box
          sx={{
            position: "absolute",
            zIndex: 999,
            left: 0,
            right: 0,
            bottom: "10px",
          }}
        >
          {!boundary && (
            <Button
              onClick={() => {
                setBoundary(true);
                //   props.router.navigate('add')
              }}
              style={{
                borderRadius: 32,
                width: 250,
                height: 48,
                color: "#707491",
              }}
              fullWidth
              variant="contained"
              color="inherit"
            >
              {" "}
              + Create a boundary
            </Button>
          )}
        </Box>
        <MapView
          height={390}
          control={boundary}
          current_loc={coordinates}
          handleAddress={handleAddress}
          createdBoundary={handleCoordinates}
          onChangeCurrentLocation={(latLng) => {
            setCoordinates(latLng);
          }}
          loading={loading}
          setDrawnLayer={state ? state.boundary : []}
        />
      </div>

      <Formik
        innerRef={formRef}
        initialValues={{
          name: state ? state.name : "",
          address: state ? state.address : "",
          type: state ? state.type : "",
          description: state ? state.description : "",
          invitedUsers: state ? state.invitedUsers : [],
          is_satellite_enable: state ? state.is_satellite_enable : false,
        }}
        validationSchema={Yup.object({
          name: Yup.string().required(lang.nameRequiredMessage),
          address: Yup.string().required(lang.addressRequiredMessage),
          type: Yup.string().required(lang.typeRequireMessage),
          description: Yup.string().required(lang.descriptionRequireMessage),
          invitedUsers: Yup.array().default([]),
          is_satellite_enable: Yup.boolean().default(false),
        })}
        onSubmit={handleLocationForm}
      >
        <Box
          sx={{
            m: 2,
            textAlign: "left",
            display: "flex",
            flexDirection: "column",
            rowGap: 1,
            pb: 5,
          }}
        >
          <Box
            sx={{
              mb: 1,
            }}
          >
            <Typography
              color="primary"
              style={{
                fontWeight: "bold",
                fontSize: 20,
              }}
            >
              Location Information
            </Typography>
          </Box>
          <Field name="name">
            {({ field, form: { touched, errors }, meta }) => (
              <React.Fragment>
                <TextField
                  {...field}
                  placeholder="Location name"
                  fullWidth
                  variant="outlined"
                />
                <ErrorMessage
                  name="name"
                  component="div"
                  className="text-red-500"
                />
              </React.Fragment>
            )}
          </Field>
          <Field name="address">
            {({ field, form: { touched, errors }, meta }) => (
              <React.Fragment>
                <TextField
                  {...field}
                  placeholder="Address"
                  fullWidth
                  variant="outlined"
                />
                <ErrorMessage
                  name="address"
                  component="div"
                  className="text-red-500"
                />
              </React.Fragment>
            )}
          </Field>
          <Field name="type">
            {({ field, form: { touched, errors }, meta }) => (
              <React.Fragment>
                <FormControl fullWidth>
                  <Select
                    {...field}
                    displayEmpty
                    renderValue={(value) => value || "Type"}
                    MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
                    sx={{
                      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#3DB3C0",
                        color: "rgb(112, 116, 145)",
                      },
                    }}
                  >
                    {crops.map((val, index) => (
                      <MenuItem key={index} value={val}>
                        {val}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <ErrorMessage
                  name="type"
                  component="div"
                  className="text-red-500"
                />
              </React.Fragment>
            )}
          </Field>
          <Field name="description">
            {({ field, form: { touched, errors }, meta }) => (
              <React.Fragment>
                <TextField
                  {...field}
                  placeholder="Description"
                  variant="outlined"
                  fullWidth
                />
                <ErrorMessage
                  name="description"
                  component="div"
                  className="text-red-500"
                />
              </React.Fragment>
            )}
          </Field>
          <Field name="invitedUsers">
            {({ field, form: { touched, errors }, meta }) => (
              <React.Fragment>
                <UserSelectComponent
                  defaultUser={invitedUsers}
                  handleSelectUser={(users) => {
                    setInvitedUsers(users);
                  }}
                />
                <ErrorMessage
                  name="invitedUsers"
                  component="div"
                  className="text-red-500"
                />
              </React.Fragment>
            )}
          </Field>
          <Field name="is_satellite_enable">
            {({ field, form: { touched, errors }, meta }) => (
              <React.Fragment>
                <Box className="flex">
                  <FormControlLabel
                    className="item-start font-bold"
                    value="start"
                    control={
                      <Switch
                        onClick={() => {
                          setSatelliteStatus(!satelliteStatus);
                          setSatelliteToggleCliked(true);
                        }}
                        checked={satelliteStatus}
                        {...field}
                        color="primary"
                      />
                    }
                    label={lang.satelliteSubscription}
                    labelPlacement="start"
                  />
                </Box>
                <ErrorMessage
                  name="is_satellite_enable"
                  component="div"
                  className="text-red-500"
                />
              </React.Fragment>
            )}
          </Field>
        </Box>
      </Formik>
      <PopUp
        {...{
          open,
          content: lang.SubscribeDialogContent,
          title: "",
          handleCancel,
          handleConfirm,
        }}
      />
    </Box>
  );
}

export default ComponentWithAuth(ComponentWithRouterProp(AddLocation));
