import React, { useState, useEffect, useRef } from "react";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Popover from "@mui/material/Popover";
import SendIcon from "@mui/icons-material/Send";
import IconButton from "@mui/material/IconButton";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Grid from "@mui/material/Grid";
import { markerCommnets, getUserList, UploadFile } from "../../utils/urls";
import ComponentList from "./CommentList";
import Attach from "../../resources/Shape.png";
import Camera from "../../resources/Camera.png";
import Send from "../../resources/Send_Up_Right.png";
import LoadingState from "../../common/Loader";
import theme from "../../constants/themes/themes";

const MentionTextField = ({ marker, handleApiSuccess, apiCallSuccess }) => {
  const divRef = React.useRef();
  const [inputValue, setInputValue] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedValues, setSelectedValues] = useState("");
  const [userlist, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputapi, setInputAPi] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [taggedUserList, setTaggedUserList] = useState([]);

  useEffect(() => {
    if (inputValue === "") {
      setShowDropdown(false);
    }
  }, [inputValue]);

  const handleInputChange = async (event) => {
    const value = event.target.value;
    const lastThreeChars = value.slice(-3);
    setInputValue(value);
    setInputAPi(inputapi);
    const lastIndex = value.lastIndexOf("@");

    const last_word = value
      .slice(lastIndex)
      .trim()
      .split(" ")
      .shift()
      .includes("@");

    if (last_word) {
      const lastTwoChars = value.slice(-2);
      const atIndex = value.lastIndexOf("@");
      const truncatedString = value.slice(atIndex + 1);
      setShowDropdown(false);

      if (
        truncatedString &&
        truncatedString.length > 1 &&
        !/\s/g.test(truncatedString)
      ) {
        try {
          let response = await getUserList(truncatedString);
          if (response.success && response.user.length > 0) {
            setShowDropdown(last_word);
            setUserList(response.user);
          }
        } catch (err) {
          console.log("err", err);
        }
      }
    }
  };

  const handleSelectChange = (event) => {
    let obj = {};
    obj.id = event._id;
    obj.user_name = event.user_name;
    const specialCharAfter = "#";
    const atIndex = inputValue.lastIndexOf("@");
    const truncatedString = inputValue.slice(0, atIndex + 1);
    const mergedString = `${truncatedString}${event.user_name}`;
    const merged = `${inputValue}${event.user_name}${specialCharAfter}`;
    setInputAPi(merged);
    setInputValue(mergedString);
    setShowDropdown(false);
    setTaggedUserList([obj, ...taggedUserList]);
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };
  useEffect(() => {
    if (selectedImage !== null) {
      addComments();
    }
    setAnchorEl(divRef.current);
  }, [selectedImage]);

  const handleButtonClick = () => {
    setSelectedValues((prevSelectedValues) => [
      ...prevSelectedValues,
      inputValue,
    ]);
    setInputValue("");
  };

  const addComments = async () => {
    if (selectedImage) {
      setLoading(true);
      let file = new FormData();
      file.append("file", selectedImage);
      file.append("type", "image/png");
      try {
        const img_res = await UploadFile(file);
        let data = {
          markerId: marker?._id,
          content: img_res.data,
          attachments: "true",
        };
        try {
          let response = await markerCommnets(data);
          if (response.success) {
            handleApiSuccess();
            setLoading(false);
            setSelectedImage(null);
          }
        } catch (err) {
          console.log("err", err);
          setSelectedImage(null);
        }
      } catch (err) {
        console.log("err", err);
      }
    } else if (inputValue && inputValue.trim() !== "") {
      setLoading(true);
      let passedId = [];
      taggedUserList.map((user) => {
        if (inputValue.includes(user.user_name)) {
          return passedId.push(user.id);
        } else {
          return;
        }
      });

      let data = {
        markerId: marker?._id,
        content: inputValue,
        attachments: "false",
        mention_users: [...new Set(passedId)],
      };
      try {
        setInputValue("");
        let response = await markerCommnets(data);
        if (response.success) {
          handleApiSuccess();
          setTaggedUserList([]);
        }
        setLoading(false);
      } catch (error) {
        console.log("err", error);
        setLoading(false);
      }
    }
  };
  return (
    <div>
      <Grid container alignItems="center" spacing={0}>
        <Grid item>
          {loading ? (
            <div style={{ paddingRight: 5 }}>
              <LoadingState
                color={theme.palette.primary.main}
                width="24px"
                height="24px"
              />
            </div>
          ) : (
            <IconButton component="label">
              <img src={Attach} width="20px" ref={divRef} />
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleImageChange}
              />
            </IconButton>
          )}
        </Grid>

        <Grid item xs sx={{ marginLeft: 0 }}>
          <TextField
            placeholder="Message"
            variant="outlined"
            value={inputValue}
            onChange={handleInputChange}
            fullWidth
            sx={{
              borderRadius: "8px",
              // backgroundColor: "white",
              "& .MuiOutlinedInput-root": {
                borderRadius: "30px",
                height: "35px",
                display: "flex",
                justifyContent: "center",
                backgroundColor: "white",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "#efefef",
                  borderWidth: "1px",
                },
              },
              "& .MuiInputLabel-root": {
                marginTop: "-10px",
              },
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "#efefef",
                  borderWidth: "1px",
                },
            }}
            InputProps={{
              endAdornment: (
                <>
                  <img
                    src={Send}
                    onClick={addComments}
                    style={{ cursor: "pointer" }}
                  />
                </>
              ),
            }}
          />
        </Grid>
        <Grid sx={{ marginTop: "5px" }}>
          <IconButton component="label" style={{ padding: "1px 5px 7px 7px" }}>
            <img src={Camera} />
            <input
              id="file-input"
              type="file"
              accept="image/*"
              capture="camera"
              style={{ display: "none" }}
              onChange={handleImageChange}
            />
          </IconButton>
        </Grid>
      </Grid>
      {showDropdown && (
        <Popover
          open={showDropdown}
          anchorEl={anchorEl}
          onClose={() => setShowDropdown(false)}
          disableAutoFocus
          className="chat-section"
          PaperProps={{
            style: {
              maxHeight: "200px",
            },
          }}
        >
          {userlist?.map((user) => (
            <MenuItem
              key={user._id}
              value={user.user_name}
              onClick={() => handleSelectChange(user)}
            >
              {user.user_name}
            </MenuItem>
          ))}
        </Popover>
      )}
    </div>
  );
};

export default MentionTextField;
