
import {
  Box,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import React, { useRef, useState} from "react";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { ComponentWithRouterProp } from "../../constants/constant";
import { lang } from "../../constants/lang";
import { routes } from "../../constants/routes";
import Logo from "../../resources/Logo.svg";
import { emailRequired, emailValidation } from "../../constants/validationMessages";
import { useNavigate } from "react-router-dom";
import { handleForgot } from "../../utils/urls";
import { ErrorMessageStyle, FormContainer, FormHeading, Label, LogoContainer } from "../Signup/style";
import SubmitButton from "../../common/SubmitButton";
import ToastMessage from "../../common/Toast";

function ForgotPassword(props) {
  const navigate = useNavigate();
  const formRef = useRef({})
  const [isLoading, SetIsLoading] = useState(false);
 
  /**
   * Method to handle login
   * @param {*} formData 
   */
  const handleForgotForm = async (formData) => {
    try {
      SetIsLoading(true);
      let response = await handleForgot(formData);
      SetIsLoading(false);
      
      ToastMessage({type:response?.success ? "success" : "error",message:response.message})
    } catch (error) {
      console.log(
        "🚀 ~ file: ForgotPassword.js:132 ~ handleForgot ~ error:",
        error
      );
    }
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={{ email: '' }}
      validationSchema={Yup.object({
        email: Yup.string()
          .required(emailRequired)
          .email(emailValidation),
      },
      )}
      onSubmit={handleForgotForm}
    >
      <Form>
        <Box
          sx={{
            textAlign: "left",
          }}
        >

          <LogoContainer onClick={() => props.router.navigate(routes.HOME)} sx={{ mb: "24px !important" }}>
            <img src={Logo} alt="pixelar-logo" />
          </LogoContainer>
          <FormContainer>


            <FormHeading variant="h1" color="primary" sx={{ p: "0 !important", m: "0!important" }} >
              {lang.forgotPassword}
            </FormHeading>
            <Typography
              color="secondary"
              variant="bodyMedium"
            >
              {lang.enterRecoveryEmail}
            </Typography>



            <Box>
            <Label>
              {lang.emailAddress}
            </Label>
            <Field name="email">
              {({
                field,
                form: { touched, errors },
                meta,
              }) => (
                <React.Fragment>
                  <TextField
                    {...field}
                    placeholder={lang.enter(lang.email)}
                    fullWidth
                  />

                  <ErrorMessage name="email" component='span' style={ErrorMessageStyle} />
                </React.Fragment>
              )}
            </Field>
            </Box>

        <Box
          sx={{
            mt: 4,
          }}
        >
            <SubmitButton isLoading={isLoading} text={lang.forgotPasswordBtnText} onClick={() => {
                formRef['current'].submitForm()
              }}/>
        </Box>

            <Box
              sx={{
                textAlign: "center",
                mt: 2,
              }}
            >
              <Typography
                variant="bodyLarge"
                color="secondary"
              >
                <Link
                  onClick={() => {
                    props.router.navigate(routes.LOGIN);
                  }}
                  style={{
                    textDecoration: "none",
                    pointerEvents:isLoading?"none":"",
                  }}
                >
                  {lang.backToSignIn}
                </Link>
              </Typography>
            </Box>
          </FormContainer>
        </Box>
    </Form>
    </Formik>
  );
}
export default ComponentWithRouterProp(ForgotPassword);
