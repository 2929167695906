import React, { useState } from "react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { Box, Button } from "@mui/material";
import { Margin } from "@mui/icons-material";
const PhotoUpload = (props) => {
  const [imageFile, setImageFile] = useState(null);

  const handleCapture = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImageFile(reader.result);
        props.onImageChange(reader.result);
        props.imageUpload(file);
      };
    }
  };

  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        backgroundColor: "white",
        position: "fixed",
        bottom: 0,
        height: "65vh",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          columnGap: 2,
          alignItems: "center",
          marginTop: 5,
        }}
      >
        {!imageFile && (
          <>
            <label for="file-input">
              <Box
                style={{
                  width: "10rem",
                  height: "10rem",
                  borderRadius: "10px",
                  backgroundImage: `url(${require("../../resources/cropImage.jpg")})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: 10,
                }}
              >
                <CameraAltIcon
                  fontSize="large"
                  style={{ color: "whitesmoke" }}
                />
              </Box>
            </label>
            <input
              id="file-input"
              type="file"
              accept="image/*"
              capture="camera"
              style={{ visibility: "hidden", width: 0, height: 0 }}
              onChange={handleCapture}
            />
          </>
        )}
        {!imageFile && (
          <Box
            sx={{
              width: "100%",
            }}
          >
            <Button
              className="positive-button"
              sx={{
                width: "90%",
                mt: 5,
              }}
            >
              <label for="file-input1">Choose Photo</label>
            </Button>
            <input
              className="mt-3 mb-5"
              id="file-input1"
              type="file"
              accept="image/*"
              style={{ visibility: "hidden", width: 0, height: 0 }}
              onChange={handleCapture}
            />
            <Button
              className="cancel-button"
              sx={{
                width: "90%",

                mt: 2,
              }}
              variant="outlined"
              onClick={() => props.onImageChange(null)}
            >
              Cancel
            </Button>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default PhotoUpload;
