import { Box } from '@mui/material';
import React from 'react';

export default function SocialButton({children}) {
  return (
    <Box sx={{
        width: 50,
        height: 50,
        display: 'flex',
        borderRadius: 10,
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        background: '#F0F0F0'
      }}>
       {children}
      </Box>
  )
}
