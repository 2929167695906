import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import {
  Box,
  IconButton,
  InputAdornment,
  Link,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { ComponentWithRouterProp } from "../../constants/constant";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { handleLogin } from "../../utils/urls";
import { lang } from "../../constants/lang";
import { routes } from "../../constants/routes";
import Logo from "../../resources/Logo.svg";
import {
  emailRequired,
  emailValidation,
  passwordRequired,
} from "../../constants/validationMessages";
import {
  ErrorMessageStyle,
  FormContainer,
  FormHeading,
  FormHeadingContainer,
  Label,
  LogoContainer,
} from "../Signup/style";
import { setLoginData } from "../../store/action/action";
import SubmitButton from "../../common/SubmitButton";
import ToastMessage from "../../common/Toast";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, db } from "../../ firebase";
import { doc, setDoc, getDoc } from "firebase/firestore";

function Login(props) {
  const navigate = useNavigate();
  const formRef = useRef({});
  const [isLoading, SetIsLoading] = useState(false);
  const [values, setValues] = useState({
    showPassword: false,
  });
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  /**
   * Methode to handle login
   * @param {*} formData
   */
  const handleLoginForm = async (formData) => {
    try {
      SetIsLoading(true);
      let response = await handleLogin(formData);
      ToastMessage({
        type: response?.token ? "success" : "error",
        message: response?.token ? lang.loginSuccess : response.message,
      });

      if (response?.token) {
        localStorage.setItem("userToken", response.token);
        props.setLoginDataAction(response.token);
        await firebaseLogin(formData, response);
        navigate(routes.LOCATION);
      } else {
        SetIsLoading(false);
      }
    } catch (error) {
      console.log("🚀 ~ file: Login.js:194 ~ handleSignup ~ error:", error);
    }
  };

  const firebaseLogin = async (formData, response) => {
    const email = formData.email;
    const password = formData.password;
    try {
      let res = await signInWithEmailAndPassword(auth, email, password);
      getFirebaseUserDBDetails(res.user.uid, response);
    } catch (err) {
      SetIsLoading(false);
      console.error(err);
    }
  };

  const getFirebaseUserDBDetails = (uid) => {
    getDoc(doc(db, "users", uid))
      .then((res) => {
        localStorage.setItem("fireUserDetails", JSON.stringify(res.data()));
      })
      .catch((err) => {
        SetIsLoading(false);
        console.log("err", err);
      });
  };

  return (
    <Formik
      innerRef={formRef}
      initialValues={{ email: "", password: "" }}
      validationSchema={Yup.object({
        email: Yup.string().required(emailRequired).email(emailValidation),
        password: Yup.string().required(passwordRequired),
      })}
      onSubmit={handleLoginForm}
    >
      <Form>
        <Box
          sx={{
            textAlign: "left",
          }}
        >
          <LogoContainer onClick={() => props.router.navigate(routes.HOME)}>
            <img src={Logo} alt="pixelar-logo" />
          </LogoContainer>
          <FormHeadingContainer>
            <FormHeading variant="h1" color="primary">
              {lang.signInToPixelar}
            </FormHeading>
            <FormHeading variant="bodyMedium" color="secondary">
              {lang.welcomeBack}
            </FormHeading>
          </FormHeadingContainer>

          <FormContainer>
            <Box>
              <Label>{lang.emailAddress}</Label>
              <Field name="email">
                {({ field, form: { touched, errors }, meta }) => (
                  <React.Fragment>
                    <TextField
                      {...field}
                      placeholder={lang.enter(lang.email)}
                      fullWidth
                    />

                    <ErrorMessage
                      name="email"
                      component="span"
                      style={ErrorMessageStyle}
                    />
                  </React.Fragment>
                )}
              </Field>
            </Box>
            <Box>
              <Label>{lang.password}</Label>
              <Field name="password">
                {({ field, form: { touched, errors }, meta }) => (
                  <React.Fragment>
                    <OutlinedInput
                      {...field}
                      id="outlined-adornment-password"
                      style={{ width: "100%" }}
                      type={values.showPassword ? "text" : "password"}
                      placeholder={lang.enter(lang.password)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {values.showPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <ErrorMessage
                      name="password"
                      component="span"
                      style={ErrorMessageStyle}
                    />
                  </React.Fragment>
                )}
              </Field>
              <Box style={{ marginTop: "16px" }}>
                <Label sx={{ textAlign: "right" }}>
                  <Link
                    onClick={() => {
                      props.router.navigate(routes.FORGOT_PASSWORD);
                    }}
                    style={{
                      textDecoration: "none",
                    }}
                  >
                    {lang.forgotPassword}
                  </Link>
                </Label>
              </Box>
            </Box>
            <Box
              sx={{
                mt: "48px",
              }}
            >
              <SubmitButton
                isLoading={isLoading}
                text={lang.signIn}
                onClick={() => {
                  formRef["current"].submitForm();
                }}
              />
            </Box>
            <Box
              sx={{
                textAlign: "center",
              }}
            >
              <Typography variant="bodyLarge" color="secondary">
                {lang.dontHaveAccount}{" "}
                <Link
                  onClick={() => {
                    props.router.navigate(routes.SIGNUP);
                  }}
                  style={{
                    textDecoration: "none",
                  }}
                >
                  {lang.signup}
                </Link>
              </Typography>
            </Box>
          </FormContainer>
        </Box>
      </Form>
    </Formik>
  );
}
// export default ComponentWithRouterProp(Login);
const mapDispatchToProps = (dispatch) => ({
  setLoginDataAction: (payload) => dispatch(setLoginData(payload)),
});
const mapStateToProps = ({ userToken }) => ({
  userToken: userToken,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComponentWithRouterProp(Login));
