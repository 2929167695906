import AddLocationAltOutlinedIcon from "@mui/icons-material/AddLocationAltOutlined";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import SpaOutlinedIcon from "@mui/icons-material/SpaOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import BottomNavigation from "@mui/material/BottomNavigation";
import MuiBottomNavigationAction from "@mui/material/BottomNavigationAction";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import * as React from "react";
import { useLocation } from "react-router-dom";
import { ComponentWithRouterProp } from "../../constants/constant";
import "./style.css";

const BottomNavigationAction = styled(MuiBottomNavigationAction)(`
  margin:5px;
  &.Mui-selected {
    color: #1B2046;
    background: #EBECF3;
    border-radius: 4px;
  }
`);
function NavBar(props) {
  const [value, setValue] = React.useState("locations");
  const location = useLocation();
  React.useEffect(() => {
    setValue(location.pathname.split("/")[1]);
  }, [location]);
  return (
    <Box
      sx={{ position: "fixed", zIndex: 999, bottom: 0, width: "100%", left: 0 }}
    >
      <BottomNavigation
        showLabels
        value={value}
        onChange={(event, newValue) => {
          props.router.navigate(`/${newValue}`);
          setValue(newValue);
        }}
        className="navbar-container"
      >
        <BottomNavigationAction
          value={"locations"}
          label="Locations"
          icon={<SpaOutlinedIcon />}
        />
        <BottomNavigationAction
          value={"notifications"}
          label="Notifications"
          icon={<AddLocationAltOutlinedIcon />}
        />
        <BottomNavigationAction
          value={"chat"}
          label="Chat"
          icon={<ChatBubbleOutlineOutlinedIcon />}
        />
        <BottomNavigationAction
          value={"profile"}
          label="Profile"
          icon={<PersonOutlineIcon />}
        />
      </BottomNavigation>
    </Box>
  );
}

export default ComponentWithRouterProp(NavBar);
