import { Box } from "@mui/material";
import React, { useEffect, useState, useRef, useCallback } from "react";
import AppBars from "../../common/AppBar/Index";
import NavBar from "../../common/NavBar/NavBar";
import NotificationList from "./NotificationList";
import { getNotificationList } from "../../utils/urls";
import LoadingState from "../../common/Loader";
import theme from "../../constants/themes/themes";
import { lang } from "../../constants/lang";
import NoDataFound from "../../common/NoDataFound";
import {
  ComponentWithAuth,
  ComponentWithRouterProp,
} from "../../constants/constant";
import FilterModel from "../../common/Filters/Filter";

function Notification() {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalNotifications, setTotalNotifications] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(5);
  const observer = useRef();
  const [filter, setFilter] = useState(false)  
  const [filterStatus, setFilterStatus] = useState(true)  
  const [filters, setFilters] = useState({
    myLocation:false,
    invitedLocation:false
  })
  const getAllNotifications = async (type) => {
    setLoading(true);
    try {
      if(type === "FILTER" || type === "RESET"){
        setNotifications([])
      }
      const response = await getNotificationList(limit, offset, filters.myLocation, filters.invitedLocation);
      if (response.success) {
        setNotifications((prev) => [...prev, ...response?.user?.notifications]);
        setTotalNotifications(response?.user?.total);
        setHasMore(response?.user?.notifications?.length > 0);
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllNotifications();
  }, [offset]);

   //filter selection
   useEffect(()=>{
    if(filters.myLocation || filters.invitedLocation){
      setFilterStatus(false)
      getAllNotifications("FILTER")
    }else{
      setFilterStatus(true)
    }
  },[filters])

  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setOffset((prev) => prev + limit);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore],
  );
  
  const setFilterSelection = (type) => {
    setFilters({
      ...filters,
      [type]: !filters[type],
    });
  };
  return (
    <Box>
      <AppBars
        title={"Notifications"}
        isBack={false}
        hasFilter={true}
        filterAction={() => {
          setFilter(filter ? false : true);
        }}
        isFilter={(val)=>{
          setFilterStatus(val)
        }}
        filterEnable={filterStatus}
      />
      <div className="notification-container">
        <div>
          {notifications.length > 0 &&
            notifications?.map((notification, index) => (
              <div
                ref={
                  notifications?.length === index + 1 ? lastElementRef : null
                }
                key={notification?._id}
              >
                <NotificationList
                  filter={filter}
                  closeFilter={(val) => {
                    setFilter(val);
                  }}
                  notificationDetails={notification}
                  index={index}
                />
              </div>
            ))}
          {loading && (
            <div style={{ marginTop: offset === 0 ? "100px" : "0px" }}>
              <LoadingState color={theme.palette.primary.main} />
            </div>
          )}
          {!loading && notifications?.length <= 0 && (
            <NoDataFound title={lang.NoNotificatonsFound} />
          )}

           {/* Filter modal */}
           <FilterModel
              filter={filter}
              closeFilter={(val)=>{
                setFilter(val)
              }}
              selectedFilter={(val) => setFilterSelection(val)}
              filters={filters}
              applyFilter={async () => {
                setLoading(true);
                setFilter(false)
                setOffset(0)
              }}
              resetFilter={ () => {
                setFilters({
                  myLocation: false,
                  invitedLocation: false,
                });
                setFilter(false)
                setOffset(0)
                getAllNotifications("RESET")
              }}
            />
        </div>
      </div>
      <NavBar />
    </Box>
  );
}
export default ComponentWithAuth(ComponentWithRouterProp(Notification));
