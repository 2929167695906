import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { Box, Button } from "@mui/material";

export const Search = styled("div")(({ theme }) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  textAlign: "left",
  borderRadius: "10px",
  backgroundColor: "rgba(118, 118, 128, 0.12)",
  width: "100%",
  height: "36px",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

export const SearchIconWrapper = styled("div")(({ theme }) => ({
  width: "24px",
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginLeft: "5px",
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    fontSize: 17,
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

export const MoreIcon = styled("div")(({ theme }) => ({
  textAlign: "right",
  height: "15px",
  position: "absolute",
  top: "10px",
  right: "0",
}));

export const PlaceholderContent = styled(Box)(({ theme }) => ({
  "&.MuiBox-root": {
    marginTop: "50%",
    marginBottom: "42px",
  },
}));
export const PlaceholderText = styled(Box)(({ theme }) => ({
  "&.MuiBox-root": {
    display: "flex",
    flexDirection: "column",
    marginBottom: "26px",
  },
}));

export const AddButton = styled(Button)(({ theme }) => ({
  "&.MuiButton-root": {
    borderRadius: 32,
    width: 200,
    left: 0,
    right: 0,
    position: "fixed",
    bottom: 70,
    fontWeight: 500,
    fontSize: 17,
  },
}));
