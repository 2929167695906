/** @format */

import React from "react";
import "./submitButton.css";
import LoadingState from "../Loader";
import { Button, Typography } from "@mui/material";
import theme from "../../constants/themes/themes";

const SubmitButton = ({
  text,
  onClick,
  disabled,
  style,
  className,
  innerRef,
  isLoading,
  color,
  sx,
}) => {
  return (
    <div>
      <Button
        ref={innerRef}
        type="submit"
        variant={"contained"}
        disabled={disabled || isLoading}
        className={`submit-btn ${className}`}
        onClick={onClick}
        style={{ ...style }}
        sx={{ ...sx }}
      >
        {isLoading ? (
          <div className="loader-center">
            <LoadingState
              color={theme.palette.secondary?.white}
              width="24px"
              height="24px"
            />
          </div>
        ) : (
          text
        )}
      </Button>
    </div>
  );
};

export default SubmitButton;
