export const REGEX = {
   // eslint-disable-next-line
  EMAIL:/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
   // eslint-disable-next-line
  PASSWORD:/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*^?&\.])[A-Za-z\d@#$!%*^?&\.]{8,15}$/,
  CONTACT: /^[\s()+-]*([0-9][\s()+-]*){11,15}$/,
  NAME: /^(?!\s)(?![\s\S]*\s$)[A-Za-z ]+$/,
  USERNAME: /^[A-Za-z][A-Za-z0-9_.]*$/,
  ONLY_TEN_DIGIT: /^[0-9]{10}$/,
  NOT_BLANK_SPACE: /^(?!\s)(?![\s\S]*\s$)/,
  ONLY_NO: /^\d+$/,
   // eslint-disable-next-line
  ONLY_CHAR: /^[0-9a-zA-Z\_-]$/,
   // eslint-disable-next-line
  PINCODE: /^(\d{5})$/,
  URL: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
  ALPHA_NUMERIC: /^([a-zA-Z0-9@&.,_ -]+)$/,
   // eslint-disable-next-line
  SPECIAL_CHAR: /^[!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]+/,
  MOBILE:/^\s+|\s+$/gm,
};
