import React, { useContext, useEffect, useRef, useState } from "react";
import { MessageList, Navbar } from "react-chat-elements";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useLocation, useNavigate } from "react-router-dom";
import { Input } from "react-chat-elements";
import { Button } from "react-chat-elements";
import SendIcon from "@mui/icons-material/Send";
import {
  ComponentWithAuth,
  ComponentWithRouterProp,
} from "../../../../constants/constant";
import { AuthContext } from "../../context/AuthContext";
import {
  arrayUnion,
  doc,
  getDoc,
  onSnapshot,
  serverTimestamp,
  Timestamp,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../../../ firebase";
import { v4 as uuid } from "uuid";
import "./conversation.css";
import moment from "moment";
import { defaultMessage } from "../../../../constants/validationMessages";
import ToastMessage from "../../../../common/Toast";
import LoadingState from "../../../../common/Loader";
import { TextField } from "@mui/material";
import Send from "../../../../resources/Send_Up_Right.png";
import Attach from "../../../../resources/Shape.png";
import Camera from "../../../../resources/Camera.png";
import IconButton from "@mui/material/IconButton";
import { currentUserFireDBDetails } from "../../../../utils/functions";

function Conversation() {
  let navigate = useNavigate();
  const { state } = useLocation();
  const { conversation_id, chatUser } = state || {};
  const [messages, setMessages] = useState([]);
  const [text, setText] = useState("");
  const [maxlength, setMaxLength] = useState(false);
  const [loading, setLoading] = useState(false);
  let inputRef = useRef();
  let messageContainerRef = useRef();
  const messagesEndRef = useRef(null);
  const { currentUser, currentUserDetails } = useContext(AuthContext);

  useEffect(() => {
    markChatRead();
    scrollToBottom();
    return () => markChatRead();
  }, [conversation_id, JSON.stringify(messages)]);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(db, "chats", conversation_id),
      (doc) => {
        const { messages } = doc.data();
        setMessages(messages);
      },
      (error) => {
        ToastMessage({
          type: "error",
          message: defaultMessage,
        });
        console.log("error", error);
      },
    );
    return () => unsubscribe();
  }, []);

  const markChatRead = async () => {
    await updateDoc(doc(db, "userChats", currentUser.uid), {
      [conversation_id + ".isNew"]: false,
    });
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({
      behavior: !loading ? "instant" : "smooth",
    });
  };

  const handleSend = async () => {
    if (text?.trim() === "") {
      return;
    }
    let user = currentUserFireDBDetails();

    inputRef.current.value = "";
    inputRef.current.style = "height: 36px";
    setLoading(true);

    try {
      await updateDoc(doc(db, "chats", conversation_id), {
        messages: arrayUnion({
          id: uuid(),
          text,
          senderId: currentUser.uid,
          senderName: user?.name,
          receiverName: chatUser.name,
          date: Timestamp.now(),
        }),
      });
      await updateDoc(doc(db, "userChats", currentUser.uid), {
        [conversation_id + ".lastMessage"]: {
          text,
        },
        [conversation_id + ".date"]: serverTimestamp(),
      });

      await updateDoc(doc(db, "userChats", chatUser.uid), {
        [conversation_id + ".lastMessage"]: {
          text,
        },
        [conversation_id + ".date"]: serverTimestamp(),
        [conversation_id + ".isNew"]: true,
      });
    } catch (err) {}

    setText("");
    setLoading(false);
  };

  const formateData = (data) => {
    const val = data.map((message) => {
      return {
        position: currentUser.uid === message.senderId ? "right" : "left",
        type: "text",
        text: message.text,
        date: moment(message.date.toDate()).format("MMMM DD, h:mm A"),
        dateString: moment(message.date.toDate()).format("MMM DD, h:mm A"),
      };
    });
    return val;
  };

  return (
    <>
      <Navbar
        left={
          <div
            onClick={() => {
              navigate("/chat");
              markChatRead();
            }}
            style={{ display: "flex", alignItems: "center" }}
          >
            <ArrowBackIosIcon
              style={{
                width: 16,
                height: 20,
              }}
            />
            <div className="pt-1">{chatUser?.name}</div>
          </div>
        }
        type="light"
      />

      <div className="chat-bg">
        <div style={{ padding: "10px 0px 0px 0px" }}>
          {messages && messages.length > 0 && (
            <>
              <MessageList
                className="message-list"
                referance={messageContainerRef}
                dataSource={formateData(messages)}
              />
            </>
          )}
          <div ref={messagesEndRef} style={{ width: 5, height: 15 }}></div>
        </div>
      </div>
      <div className="row m-4">
        <div className="col-10 input-container-chat-main">
          {/* <IconButton component="label">
            <img
              src={Attach}
              width="20px"
              // ref={divRef}
            />
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              // onChange={handleImageChange}
            />
          </IconButton> */}
          <Input
            placeholder="Message"
            multiline={true}
            referance={inputRef}
            maxHeight={55}
            minHeight={36}
            onChange={(e) => {
              setText(e.target.value);
            }}
            rightButtons={
              loading ? (
                <LoadingState color={"gray"} width="24px" height="24px" />
              ) : (
                <img
                  src={Send}
                  onClick={loading ? () => {} : handleSend}
                  style={{ cursor: "pointer", paddingRight: 10 }}
                />
              )
            }
          />
          {/* <IconButton component="label" style={{ padding: "1px 5px 7px 7px" }}>
            <img src={Camera} />
            <input
              id="file-input"
              type="file"
              accept="image/*"
              capture="camera"
              style={{ display: "none" }}
              // onChange={handleImageChange}
            />
          </IconButton> */}
        </div>
      </div>
    </>
  );
}

export default ComponentWithAuth(ComponentWithRouterProp(Conversation));
