import { Done } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, ListItem, ListItemText, Typography } from '@mui/material';
import React from 'react'
import { lang } from '../../constants/lang';

export default function FilterModel(props) {
  return (
    <div>
          <Dialog
              open={props?.filter}
              fullWidth
              onClose={() => {
                props.closeFilter(false);
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <Typography variant="subhead1">{lang.FilterTitle}</Typography>
              </DialogTitle>
              <DialogContent dividers>
                <ListItem
                  disableGutters
                  onClick={() => props.selectedFilter("myLocation")}
                  secondaryAction={
                    <>
                      {props?.filters.myLocation && (
                        <IconButton aria-label="selected">
                          <Done />
                        </IconButton>
                      )}
                    </>
                  }
                >
                  <ListItemText
                    className="text-base"
                    primary={
                      <Typography variant="body2">My Locations </Typography>
                    }
                  />
                </ListItem>
                <ListItem
                  disableGutters
                  onClick={() => props.selectedFilter("invitedLocation")}
                  secondaryAction={
                    <>
                      {props?.filters.invitedLocation && (
                        <IconButton aria-label="selected">
                          <Done />
                        </IconButton>
                      )}
                    </>
                  }
                >
                  <ListItemText
                    className="text-base"
                    primary={
                      <Typography variant="body2">Invited Location</Typography>
                    }
                  />
                </ListItem>
                <DialogActions>
                  <Button
                    onClick={async () => {
                      props.applyFilter();
                    }}
                    variant="containedLight"
                    className="positive-button"
                  >
                    {lang.ApplyFilter}
                  </Button>
                  <Button
                    onClick={async () => {
                        props.resetFilter()
                    }}
                    variant="containedLight"
                    className="cancel-button"
                  >
                    {lang.ResetFilter}
                  </Button>
                </DialogActions>
              </DialogContent>
            </Dialog>
    </div>
  )
}
