import React, { useState, useRef, useEffect } from "react";
import {
  Sheet,
  Header,
  Content,
  Footer,
  detents,
  Portal,
} from "react-sheet-slide";
import "react-sheet-slide/style.css";
import Box from "@mui/material/Box";
import { useLocation, useNavigate } from "react-router-dom";
import { deleteMarkerById, getMarkerById } from "../../utils/urls";
import { RoomOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import MentionTextField from "./CommentText";
import ComponentList from "./CommentList";
import AppBars from "../../common/AppBar/Index";
import "./Style.css";
import { lang } from "../../constants/lang";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import ToastMessage from "../../common/Toast";
import { getCurrentUser } from "../../utils/urls";
import CloseIcon from "@mui/icons-material/Close";
import { getMarkerIconType } from "../../utils/functions";
import FullScreenImage from "../../common/FullScreenImage";
import { MapView } from "../../common/MapView/MapView";

const App = () => {
  const [open, setOpen] = useState(true);
  const ref = useRef();
  const { state } = useLocation();
  const [apiData, setApiData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [apiCallSuccess, setApiCallSuccess] = useState(false);
  const [openDialog, setDialogOpen] = useState(false);
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [currentUser, setCurrentUser] = useState("");
  const [viewImage, setViewImage] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await getMarkerById(state.id);
        setApiData(response.markers.marker[0]);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching API data:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const getData = async () => {
      const response = await getCurrentUser();
      setUserId(response?.user?._id);
      setCurrentUser(response?.user?.user_name);
    };
    getData();
  }, []);

  const handleApiSuccess = () => {
    setApiCallSuccess(true);
  };
  const GetMarkerApiSuccess = () => {
    setApiCallSuccess(false);
  };
  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const deleteMarkerHandler = async (id) => {
    try {
      await deleteMarkerById(id).then((response) => {
        if (response && response.success) {
          ToastMessage({
            type: response?.error ? "error" : "error",
            message: response.message,
          });
          navigate(-1);
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const showImageFullScreen = (image) => {
    setViewImage(image);
  };

  const handleImageCloseDialog = () => {
    setViewImage(null);
  };

  return (
    <>
      <AppBars
        position="fixed"
        transparent={true}
        isBackWithLabel={true}
        isPop={apiData && userId === apiData.createdBy?._id ? true : false}
        action={() => {
          setDialogOpen(true);
        }}
      />
      <FullScreenImage
        image={viewImage}
        handleImageCloseDialog={handleImageCloseDialog}
      />

      <div
        style={{
          height: "50%",
          position: "fixed",
          left: 0,
          right: 0,
          top: 0,
        }}
      >
        {!loading ? (
          <>
            {apiData && apiData?.marker_type === "SATELLITE" ? (
              <>
                <MapView
                  height={"50vh"}
                  markers={[
                    {
                      lng: apiData?.location_id?.center_point[0],
                      lat: apiData?.location_id?.center_point[1],
                      id: apiData?._id,
                      type: apiData?.type,
                      satellite_geo: apiData?.satellite_geo?.features,
                      marker_type: apiData?.marker_type,
                    },
                  ]}
                  polygonCoordinates={
                    apiData?.location_id?.boundary[0]?.geometry?.coordinates
                  }
                  current_loc={apiData?.location_id?.center_point}
                />
              </>
            ) : (
              <>
                {apiData && apiData?.marker_image ? (
                  <img
                    src={apiData?.marker_image}
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                ) : (
                  <img
                    src={apiData?.staticImage}
                    alt="Image"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                )}
              </>
            )}
          </>
        ) : (
          ""
        )}

        <div className="address-text-container">
          <div className="marker-view-address">
            <RoomOutlined
              style={{
                color: "#fff",
                fontSize: 25,
                paddingRight: 5,
              }}
            />
            <div>
              <div>
                {apiData?.marker_type === "SATELLITE"
                  ? "Satellite Marker"
                  : apiData?.name}
              </div>
              <div
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {apiData?.marker_type === "SATELLITE"
                  ? apiData?.location_id?.address
                  : apiData?.address}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Portal>
        <div
          style={{
            height: "50%",
            position: "fixed",
            left: 0,
            right: 0,
            bottom: 0,
            zIndex: 1103,
          }}
        >
          <Sheet
            ref={ref}
            open={open}
            detents={(props) => [detents.large(props), detents.medium(props)]}
            useDarkMode={false}
            useModal={false}
            scrollingExpands={false}
          >
            <Header>
              <div
                style={{
                  display: "flex",
                  padding: "15px 15px 10px 18px",
                  alignItems: "center",
                }}
              >
                {getMarkerIconType(apiData?.type)}
                <div>
                  <div
                    className="slide-model-text-heading"
                    style={{ paddingLeft: 15 }}
                  >
                    {apiData?.type}
                  </div>
                </div>
              </div>
            </Header>
            <Content>
              <Box sx={{ margin: "50px 0 0 3px" }}>
                <ComponentList
                  marker={apiData}
                  apiCallSuccess={apiCallSuccess}
                  GetMarkerApiSuccess={GetMarkerApiSuccess}
                  showImageFullScreen={showImageFullScreen}
                />
              </Box>
            </Content>

            <Footer className="sheet-footer">
              <MentionTextField
                marker={apiData}
                handleApiSuccess={handleApiSuccess}
                apiCallSuccess={apiCallSuccess}
              />
            </Footer>
          </Sheet>
        </div>
      </Portal>

      {/* Edit ,Delete Dialog modal */}
      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ sx: { position: "fixed", bottom: 0 } }}
        sx={{
          "&.MuiPaper-root,.MuiDialog-paper": {
            margin: "auto",
            width: "100%",
            backgroundColor: "transparent",
          },
        }}
      >
        <DialogActions
          sx={{
            display: "flex",
            flexDirection: "column",
            height: "200px",
            "&.MuiDialogActions-root>:not(:first-of-type)": {
              marginLeft: 0,
            },
          }}
        >
          <Button
            variant="containedLight"
            className="positive-button"
            onClick={() => {
              apiData.mode = "edit";
              navigate("/locations/view/1/edit", {
                state: apiData,
                replace: true,
              });
            }}
          >
            {lang.EditMarker}
          </Button>
          <Button
            variant="containedLight"
            className="negative-button"
            sx={{
              color: "#FF3B30",
              fontFamily: "SF Pro Text",
              fontSize: "19px",
            }}
            onClick={() => {
              deleteMarkerHandler(state.id);
            }}
          >
            {lang.DeleteMarker}
          </Button>
          <Button
            variant="containedLight"
            className="cancel-button"
            sx={{
              color: "#007AFF",
              fontFamily: "SF Pro Text",
              fontSize: "19px",
            }}
            onClick={handleCloseDialog}
          >
            {lang.CancelBtnText}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default App;
