import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Welcome from '../../layouts/welcome';
import { setSplashScreen } from '../../store/action/action';
import Locations from '../Locations/Locations';
import './splash.css';
function SplashScreen({setSplashScreen, splash, isAuth}) {
    const [showSplash, setShowSplash] = useState(true);
    useEffect(() => {
        let timer1 = setTimeout(() => {
            setShowSplash(false)
            setSplashScreen(false)
        }, 1000);
        console.log(splash)
        return () => {
            clearTimeout(timer1);
        };
    }, [setSplashScreen])
    return (
        <>
            {showSplash && splash ? (
                <div className='splash'>PIXELAR</div>
            ) : (localStorage.getItem('userToken') && isAuth) ? <Locations /> : (<Welcome />)}
        </>

    )
}

const mapDispatchToProps = dispatch => ({
    setSplashScreen: (payload) => dispatch(setSplashScreen(payload)),
});
const mapStateToProps = ({ splash, isAuth }) => ({
    splash: splash,
    isAuth:isAuth
});
export default connect(mapStateToProps, mapDispatchToProps)(SplashScreen);
